.contact .accont-input {
  margin-top: 10px;
  width: 100%; }

.contact .divider {
  background-color: #f0f0f0;
  height: 1px;
  width: 100%;
  margin: 35px 0 25px; }

.contact .text-center {
  text-align: center; }

.contact .phone {
  padding: 10px 0;
  font-weight: 500; }

.contact .address {
  padding: 0 40px; }
