.profile-menu {
  position: relative; }
  .profile-menu .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer; }
  .profile-menu .hero-box {
    position: absolute;
    right: 0;
    width: 230px; }
    .profile-menu .hero-box .item {
      cursor: pointer;
      color: #95989A;
      font-size: 0.9em;
      padding: 4px 0; }
      .profile-menu .hero-box .item:hover {
        color: #ccc; }
