.terms-container {
  background: #fff; }
  .terms-container h1 {
    text-align: center; }
  .terms-container h5 {
    margin-top: 5vh;
    font-size: 1.1em; }
  .terms-container .italic {
    padding-left: 1em;
    font-style: italic; }
  .terms-container .sub-item {
    margin-left: 5vw; }
  .terms-container .sub-item2 {
    margin-left: 7vw; }
  .terms-container p {
    text-align: justify;
    color: #595959; }
