.profile input,
.profile select {
  color: #000; }

.profile .margintop {
  margin-top: 16px; }

.profile .flex-column {
  display: flex;
  flex-direction: column; }
  .profile .flex-column.center {
    justify-content: center; }

.profile .flex-row {
  display: flex;
  flex-direction: row; }
  .profile .flex-row.center {
    justify-content: center; }
  .profile .flex-row.space-between {
    justify-content: space-between; }

.profile .space {
  height: 100%;
  width: 50px; }

.profile .form-margin {
  margin-top: 1em; }

.profile .date-input {
  width: 100%; }

.profile .error {
  width: auto;
  color: tomato; }

.profile .profile-photo {
  margin-left: 1.5em;
  align-self: center;
  color: #C4C6C7; }

.profile .avatar-edit {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 22px;
  height: 22px;
  bottom: 20%;
  left: 6%;
  cursor: pointer;
  border-radius: 2px;
  background: rgba(0, 0, 0, 0.6); }
  .profile .avatar-edit > * {
    align-self: center;
    color: #fff; }

.profile .add-btn {
  width: auto;
  margin-top: 0;
  align-self: flex-end;
  font-weight: normal; }
  .profile .add-btn:disabled {
    background: #ccc;
    color: white;
    border: none; }

.profile .anexo-container {
  margin: 1em 0; }
  .profile .anexo-container .item-anexo {
    display: flex;
    justify-content: space-between; }

.profile .icon-anexo {
  cursor: pointer;
  color: tomato; }

.profile .flexbox {
  display: flex;
  align-self: flex-end;
  width: max-content; }

.profile .flexbox > label {
  box-sizing: border-box;
  margin-top: 0px;
  width: max-content;
  color: #fff; }

.profile .flexbox > button {
  box-sizing: border-box;
  margin-top: 0px;
  margin-left: 10px;
  width: max-content;
  padding: 10px;
  color: #fff; }

.profile .item-exp {
  display: flex;
  flex-direction: row;
  height: 70px;
  padding-left: 1em;
  background-color: rgba(180, 180, 180, 0.1);
  border-bottom: 1px solid #fff;
  margin-top: 10px; }
  .profile .item-exp .icon-exp {
    display: flex;
    flex: 1;
    justify-content: center; }
    .profile .item-exp .icon-exp span {
      align-self: center;
      cursor: pointer; }
  .profile .item-exp .item-paragraph {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }

.profile .inverse-btn {
  border: 1px solid #55ddd2;
  border-radius: 5px;
  background-color: transparent;
  -webkit-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
  color: #55ddd2;
  text-align: center; }
  .profile .inverse-btn:hover {
    background-color: #55ddd2;
    color: white; }

.profile .config-subtitle {
  color: #55ddd2;
  font-weight: 400; }

.profile .profile-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: #F0F0F0;
  padding: 2em 4%; }
  .profile .profile-container .profile-box {
    align-self: flex-start; }
  .profile .profile-container .avatar-container {
    display: flex;
    flex-direction: row;
    align-items: center; }
    .profile .profile-container .avatar-container .avatar {
      width: 70px;
      height: 70px;
      border-radius: 50%;
      margin-right: 10px; }
    .profile .profile-container .avatar-container .name {
      font-weight: bold; }
    .profile .profile-container .avatar-container .name,
    .profile .profile-container .avatar-container .email {
      color: #95989A; }
  .profile .profile-container .img-box {
    flex: 1;
    display: flex;
    flex-direction: column; }
    .profile .profile-container .img-box .accont-input {
      margin-left: 0;
      width: 100%; }
    .profile .profile-container .img-box .gender {
      margin: 0; }
    .profile .profile-container .img-box .submit-profile {
      margin-left: auto; }

.profile .img-container {
  position: relative;
  width: 100%;
  margin-left: 0;
  padding: 2%; }
  .profile .img-container .capa-tooltip,
  .profile .img-container .avatar-tooltip {
    color: white; }
  .profile .img-container .cover {
    height: 180px;
    width: 100%; }
  .profile .img-container .avatar {
    background-position: center;
    background-repeat: no-repeat;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin-left: 2%;
    cursor: pointer; }

.profile .divider {
  width: 100%;
  height: 1px;
  background: #f0f0f0;
  margin: 1em 0; }

.profile .menu {
  list-style-type: none;
  padding-left: 0; }
  .profile .menu li {
    display: flex;
    font-size: 12px;
    color: #95989A;
    padding: 0.3em 0;
    cursor: pointer; }
    .profile .menu li .item-icon {
      display: flex;
      margin-left: 1em;
      justify-content: center;
      width: 18px;
      height: 18px;
      border-radius: 10px; }
      .profile .menu li .item-icon.blue {
        background-color: #BFE6FF; }
      .profile .menu li .item-icon.red {
        background-color: #FF6347; }
        .profile .menu li .item-icon.red > * {
          color: white; }
      .profile .menu li .item-icon > * {
        font-size: 0.8em;
        align-self: center; }
  .profile .menu li:hover {
    opacity: .6; }
  .profile .menu li.selected {
    font-weight: bold; }

.profile .perfil-list {
  margin-top: 1em; }

.profile .perfil-especification {
  color: #979A9B;
  font-size: 14px; }

.profile .list-style {
  margin-top: 5px;
  margin-left: 3px;
  padding: 6px; }

.profile .perfil-item {
  padding-top: 2em; }

.profile .revisao-item {
  padding-left: 10px;
  width: 100%; }
  .profile .revisao-item .revisao-preco {
    font-size: 12px;
    color: #78C8FD;
    padding: 2px 0; }
  .profile .revisao-item .revisao-paginas {
    font-size: 12px;
    color: #A9ABAD;
    padding: 2px 0; }
  .profile .revisao-item .proposta-header {
    display: flex; }
  .profile .revisao-item .proposta-avatar {
    width: 40px;
    height: 40px;
    border-radius: 40px; }
  .profile .revisao-item .proposta-name {
    padding-left: 10px;
    align-self: center;
    flex: 1; }
  .profile .revisao-item .compras-title {
    color: #95989A;
    font-size: 14px;
    padding: 5px 0; }
  .profile .revisao-item .proposta-detalhes,
  .profile .revisao-item .compras-detalhes,
  .profile .revisao-item .revisoes-detalhes {
    cursor: pointer;
    text-decoration: underline !important;
    color: #78C8FD; }
  .profile .revisao-item .link-btn {
    width: 150px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 1em;
    font-weight: bold;
    text-align: center;
    border-radius: 5px;
    background-color: #4799e6;
    color: white; }
  .profile .revisao-item .yellow-btn {
    width: 150px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 1em;
    font-weight: bold;
    text-align: center;
    border-radius: 5px;
    background-color: darkgoldenrod;
    color: white; }
  .profile .revisao-item .green-btn {
    width: 150px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 1em;
    font-weight: bold;
    text-align: center;
    border-radius: 5px;
    background-color: #5CDCD1;
    color: white; }
  .profile .revisao-item .blue-btn {
    width: 150px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 1em;
    font-weight: bold;
    text-align: center;
    border-radius: 5px;
    background-color: #4799e6;
    color: white; }
  .profile .revisao-item .compras-detalhes,
  .profile .revisao-item .revisoes-detalhes {
    font-style: italic; }
  .profile .revisao-item .compras-pagamento {
    color: #D1D3D3; }
    .profile .revisao-item .compras-pagamento span {
      display: block; }
  .profile .revisao-item .compras-finalizada {
    color: #AEB0B1; }

.profile .tab {
  padding: 5px 15px 10px;
  color: #B7B9BA;
  cursor: pointer; }
  .profile .tab:hover {
    opacity: 0.7; }
  .profile .tab.selected {
    color: #96999B;
    font-weight: bold;
    border-bottom: 4px #42B2FC solid; }

.profile .nomargin {
  margin: 0; }

.profile .minhas-compras .compras-title,
.profile .minhas-compras .revisao-data {
  padding: 2px 0;
  font-size: 14px; }

.profile .proposta-status-msg {
  margin-left: 0;
  padding-left: 16px; }
  .profile .proposta-status-msg li {
    color: #42B2FC; }
  .profile .proposta-status-msg .margin-left-auto {
    margin-left: auto; }

.profile .proposta-status {
  align-self: center;
  font-weight: bold;
  font-size: 0.9em; }
  .profile .proposta-status.open {
    color: #42B2FC; }
  .profile .proposta-status.disapproved {
    color: #FC7965; }
  .profile .proposta-status.approved {
    color: #5CDCD1; }

.profile .proposta-item {
  color: #96999B;
  font-size: 12px;
  padding: 0.2em 0; }
  .profile .proposta-item span {
    font-weight: bold; }

.profile .proposta-name {
  padding-left: 1em;
  flex: 1;
  align-self: center;
  color: #A5A7A9; }
  .profile .proposta-name span {
    color: #96999B;
    font-weight: bold; }

.profile .revisao-data {
  color: #96999B;
  font-size: 15px;
  padding: 10px 0 2px 0; }

.profile .proposta-detalhe .button {
  font-size: 1em;
  padding: 15px 2em; }

.profile .proposta-detalhe .tomato {
  background-color: tomato;
  color: #fff;
  border: none; }
  .profile .proposta-detalhe .tomato:hover {
    opacity: 0.7; }

.profile .proposta-detalhe .back-button {
  margin-right: 1em;
  cursor: pointer; }
  .profile .proposta-detalhe .back-button:hover {
    opacity: 0.7; }

.profile .proposta-detalhe .proposta-avatar {
  width: 80px;
  height: 80px;
  border-radius: 80px; }

.profile .proposta-detalhe .proposta-total {
  color: #979A9C;
  font-weight: 500; }
  .profile .proposta-detalhe .proposta-total span {
    color: #42B2FC; }

.profile .proposta-detalhe .proposta-info {
  margin-top: 10px;
  background-color: #F5F5F5;
  border-radius: 5px;
  padding: 10px; }

.profile .proposta-detalhe .proposta-subtitle {
  color: #42B2FC; }

.profile .proposta-detalhe .proposta-descricao {
  color: #B8BBBC; }

.profile .proposta-detalhe .button {
  width: auto; }

.profile .no-proposta {
  display: block;
  margin: 1em auto;
  text-align: center;
  color: #B4B7B8;
  font-weight: 500; }

.profile .price-box {
  padding: 3em 0;
  border: 1px solid #55ddd2;
  cursor: pointer;
  margin: 20px 30px 20px 0; }
  .profile .price-box.selected {
    background: #55ddd2;
    border: none; }
    .profile .price-box.selected .stripe-turquoise {
      background: white; }
    .profile .price-box.selected * {
      color: white; }

/* Style the form - display items horizontally */
.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }

/* Add some margins for each label */
.form-inline label {
  margin: 5px 10px 5px 0; }

/* Style the input fields */
.form-inline input {
  vertical-align: middle;
  margin: 5px 10px 5px 0;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ddd; }

/* Add responsiveness - display the form controls vertically instead of horizontally on screens that are less than 800px wide */
@media (max-width: 600px) {
  .form-inline input {
    margin: 10px 0; }
  .form-inline {
    flex-direction: column;
    align-items: stretch; } }

.inner-form {
  background: #fff;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 40px;
  border: 1px solid #ddd;
  border-radius: 1px; }
  .inner-form .first-wrap {
    width: max-content;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    text-align: center; }
    .inner-form .first-wrap .w-label {
      color: #888;
      background: transparent;
      flex-grow: 1; }
  .inner-form .w-input {
    border: transparent;
    background: transparent;
    flex-grow: 1; }
