.visible {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.link-btn {
  width: 32%;
  padding-top: 10px;
  padding-bottom: 10px;

  font-size: 1em;
  font-weight: bold;
  text-align: center;

  border-radius: 5px;
  background-color: #4799e6;
  color: white;
}
