.admin-profile-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
}

.admin-profile-card {
  width: 280px;
  height: 450px;

  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 1px hsla(204, 2%, 59%, 0.5);

  margin: 8px 8px;
}

.admin-profile-card .top-row {
  margin-top: 16px;
  height: 80px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.pre-title {
  margin-top: 5vh;
  margin-bottom: 5vh;
}

.published-top {
  height: 4px;
  width: 100%;
  background-color: #3898ec;
}

.unpublished-top {
  height: 4px;
  width: 100%;
  background-color: #edbf35;
}

.deactivated-top {
  height: 4px;
  width: 100%;
  background-color: #ed3535;
}

.two-fifties {
  padding-left: 16px;
  margin: 0 0;
  height: 100%;
  width: calc(40% - 8px);
}

.three-fifties {
  margin: 0 0;
  height: 100%;
  width: calc(60% - 8px);
}

.name {
  font-size: 1.25em;
  height: fit-content;
  text-overflow: ellipsis;
  overflow: hidden;
}
.pagination {
  margin: 20px 40vw;
}
.name-published {
  color: #3898ec;
}

.name-unpublished {
  color: #edbf35;
}

.name-deactivated {
  color: #ed3535;
}

.admin-profile-card .email {
  color: #3898ec;
  font-size: 1em;
  word-wrap: break-word;
  width: 232px;
}

.date_created {
  color: #3898ec;
  font-size: 1em;
  overflow-wrap: break-word;
  word-wrap: break-word;
  width: 232px;
  margin-bottom: 5%;
}

.tag {
  color: #afafaf;
  font-size: 1em;
  overflow-wrap: break-word;
  word-wrap: break-word;
  width: 232px;
  margin-top: 5%;
}

.published {
  color: #edbf35;
  font-size: 1em;
  height: 33%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.content-row {
  height: calc(60% - 80px);
  width: calc(100% - 32px);
  margin-left: 16px;
  padding-top: 5px;
}

.action-row {
  height: calc(40% - 48px);
}

.erase {
  margin-top: 16px;
  margin-left: 16px;
  margin-right: 8px;
  display: inline-block;
  width: calc(50% - 24px);
}

.publish-unpublish {
  margin-top: 16px;
  margin-left: 8px;
  margin-right: 16px;
  display: inline-block;
  width: calc(50% - 24px);
}

.btn-bg-success {
  background-color: #3898ec;
  border-color: #3898ec;
}

.btn-bg-success:hover {
  background-color: #1878cc;
  border-color: #1878cc;
}

.btn-bg-success:focus {
  background-color: #1878cc;
  border-color: #1878cc;
}

.btn-bg-success:active {
  background-color: #0858ac;
  border-color: #0858ac;
}

.btn-bg-success:active:focus {
  background-color: #0858ac;
  border-color: #0858ac;
}

.btn-bg-success:active:hover {
  background-color: #0858ac;
  border-color: #0858ac;
}

.btn-bg-warn {
  background-color: #edbf35;
  border-color: #edbf35;
}

.modal-star {
  margin-left: 50%;
  width: 25%;
}
