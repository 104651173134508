.loading-container {
  position: fixed;
  z-index: 1000000000;
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3); }
  .loading-container div {
    text-align: center;
    color: white;
    font-size: 1.3em; }
