a {
  text-decoration: none !important;
}
a:hover {
  text-decoration: none !important;
}

/* CRIAR CONTA */

.turquoise-list.back-button {
  padding-right: 6px;
  padding-left: 6px;
  cursor: pointer;
}

.turquoise-list.back-button:hover {
  color: #95989a;
}

.blue-list.back-button {
  cursor: pointer;
}

.revisor-step2 {
  display: none;
  opacity: 0;
}

.revisor-step3 {
  display: none;
  opacity: 0;
}

.redator-step2 {
  display: none;
  opacity: 0;
}

/* INPUT RANGE BUSCA */

.side-section.last-box {
  padding-bottom: 10%;
}
.side-section span.value-left {
  font-size: 1em;
}

#slider12c .slider-track-high {
  background: #71c0f1;
}

#slider12c .slider-track-low {
  background: #71c0f1;
}

#slider12c .slider-selection {
  background: #34aeff;
}

.slider-handle {
  background: #34aeff;
}

.slider.slider-horizontal {
  width: 100%;
}

.slider.slider-horizontal .slider-track {
  height: 5px;
  width: 100%;
  margin-top: -2px;
  top: 50%;
  left: 0;
}

.slider-tick.round {
  background: #71c0f1;
}

.tooltip-inner {
  font-size: 14px;
  background-color: #34aeff !important;
  color: #fff !important;
}

.slider-handle,
.slider-tick {
  top: 3px;
  width: 15px;
  height: 15px;
}

.tooltip-arrow {
  border-bottom-color: #34aeff !important;
}

.tooltip.tooltip-main.bottom.in {
  opacity: 1;
  top: 20px !important;
}

.value-box {
  width: 100%;
}

.value-box::after::before {
  content: '';
  display: block;
  clear: both;
}

.value-left {
  display: block;
  font-size: 0.8em;
  color: #95989a;
  float: left;
}

.value-rigth {
  display: block;
  font-size: 0.8em;
  color: #95989a;
  float: right;
}

.close-filter > .link {
  text-decoration: none;
}

/* INPUT RANGE PERFIL */
.side-section #slider12c .slider-track-high {
  background: #82f5eb;
}

.side-section #slider12c .slider-track-low {
  background: #82f5eb;
}

.side-section #slider12c .slider-selection {
  background: #55ddd2;
}

.side-section #slider12c > .slider-handle {
  background: #55ddd2;
}

.side-section #slider12c .tooltip-inner {
  background-color: #55ddd2 !important;
  color: #fff !important;
}

.side-section #slider12c .tooltip-arrow {
  border-bottom-color: #55ddd2 !important;
}

a.no-style {
  text-decoration: none;
  color: #95989a;
}
a.no-style:hover {
  color: #666666;
}
a.no-style.-blue {
  text-decoration: none;
  color: #34aeff;
}
a.no-style.-turquoise {
  text-decoration: none;
  color: #55ddd2;
}

@media (max-width: 479px) {
  /* .filter-dropdown{
        display: none;
        opacity: 0;
        position: fixed !important;
        overflow: hidden;
    } */

  .filter-item {
    padding-bottom: 15% !important;
  }

  /* INPUT RANGE BUSCA */
  #slider12c .slider-track-high {
    background: #f1f1f1 !important;
  }

  #slider12c .slider-track-low {
    background: #f1f1f1 !important;
  }

  #slider12c .slider-selection {
    background-color: #fff !important;
  }

  .slider-handle {
    background: #fff !important;
  }

  .slider.slider-horizontal .slider-track {
    height: 5px;
    width: 100%;
    margin-top: -2px;
    top: 50%;
    left: 0;
  }

  .slider-tick.round {
    background: #71c0f1 !important;
  }

  .tooltip-inner {
    font-size: 14px;
    background-color: #fff !important;
    color: #34aeff !important;
  }

  .slider-handle,
  .slider-tick {
    top: 3px;
    width: 15px;
    height: 15px;
  }

  .value-left {
    color: #fff;
  }

  .value-rigth {
    color: #fff;
  }

  /* INPUT RANGE PERFIL */
  .side-section #slider12c .slider-track-high {
    background: #82f5eb !important;
  }

  .side-section #slider12c .slider-track-low {
    background: #82f5eb !important;
  }

  .side-section #slider12c .slider-selection {
    background: #55ddd2 !important;
  }

  .side-section #slider12c > .slider-handle {
    background: #55ddd2 !important;
  }

  .side-section #slider12c .tooltip-inner {
    background-color: #55ddd2 !important;
  }

  .side-section #slider12c .tooltip-arrow {
    border-bottom-color: #55ddd2 !important;
  }

  /* FILTER BUTTON */
  .apply-btn {
    display: block;
  }
}

/* LOAD MORE BUTTON */

.load-more {
  min-height: 600px;
  position: relative;
  overflow: hidden;
}
.load-more .loadmore {
  /*position: absolute;*/
  bottom: 0;
  left: 7%;
  width: 100%;
  margin: 0;
  /*padding: 30px 0;*/

  /* "transparent" only works here because == rgba(0,0,0,0) */
  background-image: linear-gradient(to bottom, transparent, white);
}

.show-more {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
