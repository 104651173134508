.notification {
  position: fixed;
  height: 5em;
  width: 100%;
  top: 0;
  color: white;
  z-index: 1100000;
  display: flex;
  flex-direction: row;
  align-items: center; }
  .notification.error {
    background-color: #FC644E; }
  .notification.warning {
    background-color: #FECB2F; }
  .notification.success {
    background-color: #55ddd2; }
  .notification span:nth-child(1) {
    flex: 3;
    text-align: center; }
  .notification .close {
    cursor: pointer;
    padding: 1em;
    margin-left: auto; }
