.profile-preview {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: #F0F0F0;
  z-index: 2000;
  overflow-x: hidden; }
  .profile-preview .preview-footer {
    height: 70px;
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: 2001;
    background-color: #34aeff;
    color: #fff; }
  .profile-preview .close {
    float: right;
    font-size: 1em;
    margin: 15px 30px 0 0;
    background: transparent;
    border: 1px solid white;
    color: white;
    padding: 8px 15px;
    width: auto; }
