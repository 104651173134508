.flex-container-jumbo {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.flex-container-jumbo div {
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 0;
}

.logo {
  width: 120px;
  height: 168px;
}

#subtitle {
  margin-bottom: 1%;
}

#seta {
  margin-bottom: 1%;
}

@media only screen and (max-width: 375px) {
  .logo {
    width: 100px;
    height: 150px;
  }
  .flex-container-jumbo {
    height: fit-content;
  }

  #subtitle {
    margin-bottom: 2%;
  }

  #seta {
    margin-bottom: 3%;
  }
}
