button {
  outline: none;
}

@font-face {
  font-family: 'webflow-icons';
  src: url(data:application/x-font-ttf;charset=utf-8;base64,AAEAAAALAIAAAwAwT1MvMg6SAy0AAAC8AAAAYGNtYXAaVcxaAAABHAAAAExnYXNwAAAAEAAAAWgAAAAIZ2x5ZgscV1gAAAFwAAABhGhlYWQCkFKvAAAC9AAAADZoaGVhB0MDyQAAAywAAAAkaG10eBIAA10AAANQAAAAIGxvY2EBMADyAAADcAAAABJtYXhwAAwATQAAA4QAAAAgbmFtZWTuiIAAAAOkAAABe3Bvc3QAAwAAAAAFIAAAACAAAwQAAZAABQAAApkCzAAAAI8CmQLMAAAB6wAzAQkAAAAAAAAAAAAAAAAAAAABEAAAAAAAAAAAAAAAAAAAAABAAADmAwPA/8D/wAPAAEAAAAABAAAAAAAAAAAAAAAgAAAAAAACAAAAAwAAABQAAwABAAAAFAAEADgAAAAKAAgAAgACAAEAIOYD//3//wAAAAAAIOYA//3//wAB/+MaBAADAAEAAAAAAAAAAAAAAAEAAf//AA8AAQAAAAAAAAAAAAIAADc5AQAAAAABAAAAAAAAAAAAAgAANzkBAAAAAAEAAAAAAAAAAAACAAA3OQEAAAAAAQEgAAADIAOAAAUAAAkBBwkBFwMg/kBAAYD+gEABwAHAQP6A/oBAAAEA4AAAAuADgAAFAAATARcJAQfgAcBA/oABgEABwAHAQP6A/oBAAAADAMAA4ANAAsAAGAAxAEoAAAEhIg4CHQEUHgIzITI+Aj0BNC4CIxUhIg4CHQEUHgIzITI+Aj0BNC4CIxUhIg4CHQEUHgIzITI+Aj0BNC4CIwMg/cAHCwkFBQkLBwJABwsJBQUJCwf9wAcLCQUFCQsHAkAHCwkFBQkLB/3ABwsJBQUJCwcCQAcLCQUFCQsHAsAFCQsHIAcLCQUFCQsHIAcLCQXABQkLByAHCwkFBQkLByAHCwkFwAUJCwcgBwsJBQUJCwcgBwsJBQAAAAABAJ0AtAOBApUABQAACQIHCQEDJP7r/upcAXEBcgKU/usBFFv+egGGAAAAAAEAAAABAADSLAJOXw889QALBAAAAAAAz/iHGQAAAADP+IcZAAAAAAOBA4AAAAAIAAIAAAAAAAAAAQAAA8D/wAAABAAAAAAAA4EAAQAAAAAAAAAAAAAAAAAAAAgAAAAAAAAAAAAAAAACAAAABAABIAQAAOAEAADABAAAnQAAAAAACgAUAB4AMgBGAKwAwgAAAAEAAAAIAEsAAwAAAAAAAgAAAAAAAAAAAAAAAAAAAAAAAAAOAK4AAQAAAAAAAQAaAAAAAQAAAAAAAgAOAHEAAQAAAAAAAwAaADAAAQAAAAAABAAaAH8AAQAAAAAABQAWABoAAQAAAAAABgANAEoAAQAAAAAACgA0AJkAAwABBAkAAQAaAAAAAwABBAkAAgAOAHEAAwABBAkAAwAaADAAAwABBAkABAAaAH8AAwABBAkABQAWABoAAwABBAkABgAaAFcAAwABBAkACgA0AJkAdwBlAGIAZgBsAG8AdwAtAGkAYwBvAG4AcwBWAGUAcgBzAGkAbwBuACAAMQAuADAAdwBlAGIAZgBsAG8AdwAtAGkAYwBvAG4Ac3dlYmZsb3ctaWNvbnMAdwBlAGIAZgBsAG8AdwAtAGkAYwBvAG4AcwBSAGUAZwB1AGwAYQByAHcAZQBiAGYAbABvAHcALQBpAGMAbwBuAHMARgBvAG4AdAAgAGcAZQBuAGUAcgBhAHQAZQBkACAAYgB5ACAASQBjAG8ATQBvAG8AbgAuAAADAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA);
  font-weight: normal;
  font-style: normal;
}

/* NATIVE CSS */

[class^='w-icon-'],
[class*=' w-icon-'] {
  font-family: 'webflow-icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.w-icon-slider-right:before {
  content: '\e600';
}
.w-icon-slider-left:before {
  content: '\e601';
}
.w-icon-nav-menu:before {
  content: '\e602';
}
.w-icon-arrow-down:before,
.w-icon-dropdown-toggle:before {
  content: '\e603';
}
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
html {
  height: 100%;
}
body {
  margin: 0;
  bottom: 0px;
  min-height: 100%;
  background-color: #f0f0f0;
  font-family: Lato, sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #333;
  text-decoration: none;
}
img {
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
}
html.w-mod-touch * {
  background-attachment: scroll !important;
}
.w-block {
  display: block;
}
.w-inline-block {
  max-width: 100%;
  display: inline-block;
}
.w-clearfix:before,
.w-clearfix:after {
  content: ' ';
  display: table;
}
.w-clearfix:after {
  clear: both;
}
.w-hidden {
  display: none;
}
.w-button {
  display: inline-block;
  padding: 9px 15px;
  background-color: #3898ec;
  color: white;
  border: 0;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  border-radius: 0;
}

.w-button:disabled {
  background-color: #ccc !important;
}

input.w-button {
  -webkit-appearance: button;
}
html[data-w-dynpage] [data-w-cloak] {
  color: transparent !important;
}
.w-webflow-badge,
.w-webflow-badge * {
  position: static;
  left: auto;
  top: auto;
  right: auto;
  bottom: auto;
  z-index: auto;
  display: block;
  visibility: visible;
  overflow: visible;
  overflow-x: visible;
  overflow-y: visible;
  box-sizing: border-box;
  width: auto;
  height: auto;
  max-height: none;
  max-width: none;
  min-height: 0;
  min-width: 0;
  margin: 0;
  padding: 0;
  float: none;
  clear: none;
  border: 0 none transparent;
  border-radius: 0;
  background: none;
  background-image: none;
  background-position: 0% 0%;
  background-size: auto auto;
  background-repeat: repeat;
  background-origin: padding-box;
  background-clip: border-box;
  background-attachment: scroll;
  background-color: transparent;
  box-shadow: none;
  opacity: 1;
  transform: none;
  transition: none;
  direction: ltr;
  font-family: inherit;
  font-weight: inherit;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  font-style: inherit;
  font-variant: inherit;
  text-align: inherit;
  letter-spacing: inherit;
  text-decoration: inherit;
  text-indent: 0;
  text-transform: inherit;
  list-style-type: disc;
  text-shadow: none;
  font-smoothing: auto;
  vertical-align: baseline;
  cursor: inherit;
  white-space: inherit;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
}
.w-webflow-badge {
  position: fixed !important;
  display: inline-block !important;
  visibility: visible !important;
  opacity: 1 !important;
  z-index: 2147483647 !important;
  top: auto !important;
  right: 12px !important;
  bottom: 12px !important;
  left: auto !important;
  color: #aaadb0 !important;
  background-color: #fff !important;
  border-radius: 3px !important;
  padding: 6px 8px 6px 6px !important;
  font-size: 12px !important;
  opacity: 1 !important;
  line-height: 14px !important;
  text-decoration: none !important;
  transform: none !important;
  margin: 0 !important;
  width: auto !important;
  height: auto !important;
  overflow: visible !important;
  white-space: nowrap;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.1);
}
.w-webflow-badge > img {
  display: inline-block !important;
  visibility: visible !important;
  opacity: 1 !important;
  vertical-align: middle !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
  margin-bottom: 10px;
}
h1 {
  font-size: 38px;
  line-height: 44px;
  margin-top: 20px;
}
h2 {
  font-size: 32px;
  line-height: 36px;
  margin-top: 20px;
}
h3 {
  font-size: 24px;
  line-height: 30px;
  margin-top: 20px;
}
h4 {
  font-size: 18px;
  line-height: 24px;
  margin-top: 10px;
}
h5 {
  font-size: 14px;
  line-height: 20px;
  margin-top: 10px;
}
h6 {
  font-size: 12px;
  line-height: 18px;
  margin-top: 10px;
}
p {
  margin-top: 0;
  margin-bottom: 10px;
}
blockquote {
  margin: 0 0 10px 0;
  padding: 10px 20px;
  border-left: 5px solid #e2e2e2;
  font-size: 18px;
  line-height: 22px;
}
figure {
  margin: 0;
  margin-bottom: 10px;
}
figcaption {
  margin-top: 5px;
  text-align: center;
}
ul,
ol {
  margin-top: 0px;
  margin-bottom: 10px;
  padding-left: 40px;
}
.w-list-unstyled {
  padding-left: 0;
  list-style: none;
}
.w-embed:before,
.w-embed:after {
  content: ' ';
  display: table;
}
.w-embed:after {
  clear: both;
}
.w-video {
  width: 100%;
  position: relative;
  padding: 0;
}
.w-video iframe,
.w-video object,
.w-video embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
fieldset {
  padding: 0;
  margin: 0;
  border: 0;
}
button,
html input[type='button'],
input[type='reset'] {
  border: 0;
  cursor: pointer;
  -webkit-appearance: button;
}
.w-form {
  margin: 0 0 15px;
}

.w-form .error {
  color: #fc644e;
  margin: 0 auto 10px;
}

.w-form-done {
  display: none;
  padding: 20px;
  text-align: center;
  background-color: #dddddd;
}
.w-form-fail {
  display: none;
  margin-top: 10px;
  padding: 10px;
  background-color: #ffdede;
}
label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}
.w-input,
.w-select {
  display: block;
  width: 100%;
  height: 38px;
  padding: 5px 12px;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 1.428571429;
  color: #333333;
  background-color: #ffffff;
  border-radius: 0px !important;
  border: 1px solid #cccccc;
}
.w-input:-moz-placeholder,
.w-select:-moz-placeholder {
  color: #999;
}
.w-input::-moz-placeholder,
.w-select::-moz-placeholder {
  color: #999;
  opacity: 1;
}
.w-input:-ms-input-placeholder,
.w-select:-ms-input-placeholder {
  color: #999;
}
.w-input::-webkit-input-placeholder,
.w-select::-webkit-input-placeholder {
  color: #999;
}
.w-input:focus,
.w-select:focus {
  border-color: #3898ec;
  outline: 0;
}

.w-input.error {
  border-color: tomato;
  outline: 0;
}

.is-invalid-input {
  border-bottom-color: #fc644e !important;
}

.w-input[disabled],
.w-select[disabled],
.w-input[readonly],
.w-select[readonly],
fieldset[disabled] .w-input,
fieldset[disabled] .w-select {
  cursor: not-allowed;
  background-color: #eeeeee;
}
textarea.w-input,
textarea.w-select {
  height: auto;
}
/* .w-select {
  background-image: -webkit-linear-gradient(white 0%, #f3f3f3 100%);
  background-image: linear-gradient(white 0%, #f3f3f3 100%);
} */
.w-select[multiple] {
  height: auto;
}
.w-form-label {
  display: inline-block;
  cursor: pointer;
  font-weight: normal;
  margin-bottom: 0px;
}
.w-checkbox,
.w-radio {
  display: block;
  margin-bottom: 5px;
  align-items: space-between;
}
.w-checkbox:before,
.w-radio:before,
.w-checkbox:after,
.w-radio:after {
  content: ' ';
  display: table;
}
.w-checkbox:after,
.w-radio:after {
  clear: both;
}
.w-checkbox-input,
.w-radio-input {
  margin: 4px 0 0;
  margin-top: 1px \9;
  line-height: normal;
  float: left;
  margin-left: -20px;
}
.w-radio-input {
  margin-top: 3px;
}
.w-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 75em;
}
.w-container:before,
.w-container:after {
  content: ' ';
  display: table;
}
.w-container:after {
  clear: both;
}
.w-container .w-row {
  margin-left: -1em;
  margin-right: -1em;
}
.w-row:before,
.w-row:after {
  content: ' ';
  display: table;
}
.w-row:after {
  clear: both;
}
.w-row .w-row {
  margin-left: 0;
  margin-right: 0;
}
.w-col {
  position: relative;
  float: left;
  width: 100%;
  min-height: 1px;
  padding-left: 10px;
  padding-right: 10px;
}
.w-col .w-col {
  padding-left: 0;
  padding-right: 0;
}
.w-col-1 {
  width: 8.33333333%;
}
.w-col-2 {
  width: 16.66666667%;
}
.w-col-3 {
  width: 25%;
}
.w-col-4 {
  width: 33.33333333%;
}
.w-col-5 {
  width: 41.66666667%;
}
.w-col-6 {
  width: 50%;
}
.w-col-7 {
  width: 58.33333333%;
}
.w-col-8 {
  width: 66.66666667%;
}
.w-col-9 {
  width: 75%;
}
.w-col-10 {
  width: 83.33333333%;
}
.w-col-11 {
  width: 91.66666667%;
}
.w-col-12 {
  width: 100%;
}
.w-hidden-main {
  display: none !important;
}
@media screen and (max-width: 75em) {
  .w-container {
    max-width: 72em;
  }
  .w-hidden-main {
    display: inherit !important;
  }
  .w-hidden-medium {
    display: none !important;
  }
  .w-col-medium-1 {
    width: 8.33333333%;
  }
  .w-col-medium-2 {
    width: 16.66666667%;
  }
  .w-col-medium-3 {
    width: 25%;
  }
  .w-col-medium-4 {
    width: 33.33333333%;
  }
  .w-col-medium-5 {
    width: 41.66666667%;
  }
  .w-col-medium-6 {
    width: 50%;
  }
  .w-col-medium-7 {
    width: 58.33333333%;
  }
  .w-col-medium-8 {
    width: 66.66666667%;
  }
  .w-col-medium-9 {
    width: 75%;
  }
  .w-col-medium-10 {
    width: 83.33333333%;
  }
  .w-col-medium-11 {
    width: 91.66666667%;
  }
  .w-col-medium-12 {
    width: 100%;
  }
  .w-col-stack {
    width: 100%;
    left: auto;
    right: auto;
  }
}
@media screen and (max-width: 55em) {
  .w-hidden-main {
    display: inherit !important;
  }
  .w-hidden-medium {
    display: inherit !important;
  }
  .w-hidden-small {
    display: none !important;
  }
  .w-row,
  .w-container .w-row {
    margin-left: 0;
    margin-right: 0;
  }
  .w-col {
    width: 100%;
    left: auto;
    right: auto;
  }
  .w-col-small-1 {
    width: 8.33333333%;
  }
  .w-col-small-2 {
    width: 16.66666667%;
  }
  .w-col-small-3 {
    width: 25%;
  }
  .w-col-small-4 {
    width: 33.33333333%;
  }
  .w-col-small-5 {
    width: 41.66666667%;
  }
  .w-col-small-6 {
    width: 50%;
  }
  .w-col-small-7 {
    width: 58.33333333%;
  }
  .w-col-small-8 {
    width: 66.66666667%;
  }
  .w-col-small-9 {
    width: 75%;
  }
  .w-col-small-10 {
    width: 83.33333333%;
  }
  .w-col-small-11 {
    width: 91.66666667%;
  }
  .w-col-small-12 {
    width: 100%;
  }
}
@media screen and (max-width: 38.32em) {
  .w-container {
    max-width: none;
  }
  .w-hidden-main {
    display: inherit !important;
  }
  .w-hidden-medium {
    display: inherit !important;
  }
  .w-hidden-small {
    display: inherit !important;
  }
  .w-hidden-tiny {
    display: none !important;
  }
  .w-col {
    width: 100%;
  }
  .w-col-tiny-1 {
    width: 8.33333333%;
  }
  .w-col-tiny-2 {
    width: 16.66666667%;
  }
  .w-col-tiny-3 {
    width: 25%;
  }
  .w-col-tiny-4 {
    width: 33.33333333%;
  }
  .w-col-tiny-5 {
    width: 41.66666667%;
  }
  .w-col-tiny-6 {
    width: 50%;
  }
  .w-col-tiny-7 {
    width: 58.33333333%;
  }
  .w-col-tiny-8 {
    width: 66.66666667%;
  }
  .w-col-tiny-9 {
    width: 75%;
  }
  .w-col-tiny-10 {
    width: 83.33333333%;
  }
  .w-col-tiny-11 {
    width: 91.66666667%;
  }
  .w-col-tiny-12 {
    width: 100%;
  }
}
.w-widget {
  position: relative;
}
.w-widget-map {
  width: 100%;
  height: 400px;
}
.w-widget-map label {
  width: auto;
  display: inline;
}
.w-widget-map img {
  max-width: inherit;
}
.w-widget-map .gm-style-iw {
  width: 90% !important;
  height: auto !important;
  top: 7px !important;
  left: 6% !important;
  display: inline;
  text-align: center;
  overflow: hidden;
}
.w-widget-map .gm-style-iw + div {
  display: none;
}
.w-widget-twitter {
  overflow: hidden;
}
.w-widget-twitter-count-shim {
  display: inline-block;
  vertical-align: top;
  position: relative;
  width: 28px;
  height: 20px;
  text-align: center;
  background: white;
  border: #758696 solid 1px;
  border-radius: 3px;
}
.w-widget-twitter-count-shim * {
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.w-widget-twitter-count-shim .w-widget-twitter-count-inner {
  position: relative;
  font-size: 15px;
  line-height: 12px;
  text-align: center;
  color: #999;
  font-family: serif;
}
.w-widget-twitter-count-shim .w-widget-twitter-count-clear {
  position: relative;
  display: block;
}
.w-widget-twitter-count-shim.w--large {
  width: 36px;
  height: 28px;
  margin-left: 7px;
}
.w-widget-twitter-count-shim.w--large .w-widget-twitter-count-inner {
  font-size: 18px;
  line-height: 18px;
}
.w-widget-twitter-count-shim:not(.w--vertical) {
  margin-left: 5px;
  margin-right: 8px;
}
.w-widget-twitter-count-shim:not(.w--vertical).w--large {
  margin-left: 6px;
}
.w-widget-twitter-count-shim:not(.w--vertical):before,
.w-widget-twitter-count-shim:not(.w--vertical):after {
  top: 50%;
  left: 0;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.w-widget-twitter-count-shim:not(.w--vertical):before {
  border-color: rgba(117, 134, 150, 0);
  border-right-color: #5d6c7b;
  border-width: 4px;
  margin-left: -9px;
  margin-top: -4px;
}
.w-widget-twitter-count-shim:not(.w--vertical).w--large:before {
  border-width: 5px;
  margin-left: -10px;
  margin-top: -5px;
}
.w-widget-twitter-count-shim:not(.w--vertical):after {
  border-color: rgba(255, 255, 255, 0);
  border-right-color: white;
  border-width: 4px;
  margin-left: -8px;
  margin-top: -4px;
}
.w-widget-twitter-count-shim:not(.w--vertical).w--large:after {
  border-width: 5px;
  margin-left: -9px;
  margin-top: -5px;
}
.w-widget-twitter-count-shim.w--vertical {
  width: 61px;
  height: 33px;
  margin-bottom: 8px;
}
.w-widget-twitter-count-shim.w--vertical:before,
.w-widget-twitter-count-shim.w--vertical:after {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.w-widget-twitter-count-shim.w--vertical:before {
  border-color: rgba(117, 134, 150, 0);
  border-top-color: #5d6c7b;
  border-width: 5px;
  margin-left: -5px;
}
.w-widget-twitter-count-shim.w--vertical:after {
  border-color: rgba(255, 255, 255, 0);
  border-top-color: white;
  border-width: 4px;
  margin-left: -4px;
}
.w-widget-twitter-count-shim.w--vertical .w-widget-twitter-count-inner {
  font-size: 18px;
  line-height: 22px;
}
.w-widget-twitter-count-shim.w--vertical.w--large {
  width: 76px;
}
.w-widget-gplus {
  overflow: hidden;
}
.w-background-video {
  position: relative;
  overflow: hidden;
  height: 500px;
  color: white;
}
.w-background-video > video {
  background-size: cover;
  background-position: 50% 50%;
  position: absolute;
  right: -100%;
  bottom: -100%;
  top: -100%;
  left: -100%;
  margin: auto;
  min-width: 100%;
  min-height: 100%;
  z-index: -100;
}
.w-slider {
  position: relative;
  height: 300px;
  text-align: center;
  background: #dddddd;
  clear: both;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0);
}
.w-slider-mask {
  position: relative;
  display: block;
  overflow: hidden;
  z-index: 1;
  left: 0;
  right: 0;
  height: 100%;
  white-space: nowrap;
}
.w-slide {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 100%;
  height: 100%;
  white-space: normal;
  text-align: left;
}
.w-slider-nav {
  position: absolute;
  z-index: 2;
  top: auto;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  padding-top: 10px;
  height: 40px;
  text-align: center;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0);
}
.w-slider-nav.w-round > div {
  border-radius: 100%;
}
.w-slider-nav.w-num > div {
  width: auto;
  height: auto;
  padding: 0.2em 0.5em;
  font-size: inherit;
  line-height: inherit;
}
.w-slider-nav.w-shadow > div {
  box-shadow: 0 0 3px rgba(51, 51, 51, 0.4);
}
.w-slider-nav-invert {
  color: #fff;
}
.w-slider-nav-invert > div {
  background-color: rgba(34, 34, 34, 0.4);
}
.w-slider-nav-invert > div.w-active {
  background-color: #222;
}
.w-slider-dot {
  position: relative;
  display: inline-block;
  width: 1em;
  height: 1em;
  background-color: rgba(255, 255, 255, 0.4);
  cursor: pointer;
  margin: 0 3px 0.5em;
  transition: background-color 100ms, color 100ms;
}
.w-slider-dot.w-active {
  background-color: #fff;
}
.w-slider-arrow-left,
.w-slider-arrow-right {
  position: absolute;
  width: 80px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  cursor: pointer;
  overflow: hidden;
  color: white;
  font-size: 40px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.w-slider-arrow-left [class^='w-icon-'],
.w-slider-arrow-right [class^='w-icon-'],
.w-slider-arrow-left [class*=' w-icon-'],
.w-slider-arrow-right [class*=' w-icon-'] {
  position: absolute;
}
.w-slider-arrow-left {
  z-index: 3;
  right: auto;
}
.w-slider-arrow-right {
  z-index: 4;
  left: auto;
}
.w-icon-slider-left,
.w-icon-slider-right {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 1em;
  height: 1em;
}
.w-dropdown {
  display: inline-block;
  position: relative;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  z-index: 900;
}
.w-dropdown-btn,
.w-dropdown-toggle,
.w-dropdown-link {
  position: relative;
  vertical-align: top;
  text-decoration: none;
  color: #222222;
  padding: 20px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  white-space: nowrap;
}
.w-dropdown-toggle {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: inline-block;
  cursor: pointer;
  padding-right: 40px;
}
.w-icon-dropdown-toggle {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  margin-right: 20px;
  width: 1em;
  height: 1em;
}
.w-dropdown-list {
  position: absolute;
  background: #dddddd;
  display: none;
  min-width: 100%;
}
.w-dropdown-list.w--open {
  display: block;
}
.w-dropdown-link {
  padding: 10px 20px;
  display: block;
  color: #222222;
}
.w-dropdown-link.w--current {
  color: #0082f3;
}
.w-nav[data-collapse='all'] .w-dropdown,
.w-nav[data-collapse='all'] .w-dropdown-toggle {
  display: block;
}
.w-nav[data-collapse='all'] .w-dropdown-list {
  position: static;
}
@media screen and (max-width: 75em) {
  .w-nav[data-collapse='medium'] .w-dropdown,
  .w-nav[data-collapse='medium'] .w-dropdown-toggle {
    display: block;
  }
  .w-nav[data-collapse='medium'] .w-dropdown-list {
    position: static;
  }
}
@media screen and (max-width: 55em) {
  .w-nav[data-collapse='small'] .w-dropdown,
  .w-nav[data-collapse='small'] .w-dropdown-toggle {
    display: block;
  }
  .w-nav[data-collapse='small'] .w-dropdown-list {
    position: static;
  }
  .w-nav-brand {
    padding-left: 10px;
  }
}
@media screen and (max-width: 38.32em) {
  .w-nav[data-collapse='tiny'] .w-dropdown,
  .w-nav[data-collapse='tiny'] .w-dropdown-toggle {
    display: block;
  }
  .w-nav[data-collapse='tiny'] .w-dropdown-list {
    position: static;
  }
}
/**
 * ## Note
 * Safari (on both iOS and OS X) does not handle viewport units (vh, vw) well.
 * For example percentage units do not work on descendants of elements that
 * have any dimensions expressed in viewport units. It also doesn’t handle them at
 * all in `calc()`.
 */
/**
 * Wrapper around all lightbox elements
 *
 * 1. Since the lightbox can receive focus, IE also gives it an outline.
 * 2. Fixes flickering on Chrome when a transition is in progress
 *    underneath the lightbox.
 */
.w-lightbox-backdrop {
  color: #000;
  cursor: auto;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  list-style: disc;
  text-align: start;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  color: #fff;
  font-family: 'Helvetica Neue', Helvetica, Ubuntu, 'Segoe UI', Verdana,
    sans-serif;
  font-size: 17px;
  line-height: 1.2;
  font-weight: 300;
  text-align: center;
  background: rgba(0, 0, 0, 0.9);
  z-index: 2000;
  outline: 0;
  /* 1 */
  opacity: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-transform: translate(0, 0);
  /* 2 */
}
/**
 * Neat trick to bind the rubberband effect to our canvas instead of the whole
 * document on iOS. It also prevents a bug that causes the document underneath to scroll.
 */
.w-lightbox-backdrop,
.w-lightbox-container {
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.w-lightbox-content {
  position: relative;
  height: 100vh;
  overflow: hidden;
}
.w-lightbox-view {
  position: absolute;
  width: 100vw;
  height: 100vh;
  opacity: 0;
}
.w-lightbox-view:before {
  content: '';
  height: 100vh;
}
/* .w-lightbox-content */
.w-lightbox-group,
.w-lightbox-group .w-lightbox-view,
.w-lightbox-group .w-lightbox-view:before {
  height: 86vh;
}
.w-lightbox-frame,
.w-lightbox-view:before {
  display: inline-block;
  vertical-align: middle;
}
/*
 * 1. Remove default margin set by user-agent on the <figure> element.
 */
.w-lightbox-figure {
  position: relative;
  margin: 0;
  /* 1 */
}
.w-lightbox-group .w-lightbox-figure {
  cursor: pointer;
}
/**
 * IE adds image dimensions as width and height attributes on the IMG tag,
 * but we need both width and height to be set to auto to enable scaling.
 */
.w-lightbox-img {
  width: auto;
  height: auto;
  max-width: none;
}
/**
 * 1. Reset if style is set by user on "All Images"
 */
.w-lightbox-image {
  display: block;
  float: none;
  /* 1 */
  max-width: 100vw;
  max-height: 100vh;
}
.w-lightbox-group .w-lightbox-image {
  max-height: 86vh;
}
.w-lightbox-caption {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0.5em 1em;
  background: rgba(0, 0, 0, 0.4);
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.w-lightbox-embed {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.w-lightbox-control {
  position: absolute;
  top: 0;
  width: 4em;
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.w-lightbox-left {
  display: none;
  bottom: 0;
  left: 0;
  /* <svg xmlns="http://www.w3.org/2000/svg" viewBox="-20 0 24 40" width="24" height="40"><g transform="rotate(45)"><path d="m0 0h5v23h23v5h-28z" opacity=".4"/><path d="m1 1h3v23h23v3h-26z" fill="#fff"/></g></svg> */
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii0yMCAwIDI0IDQwIiB3aWR0aD0iMjQiIGhlaWdodD0iNDAiPjxnIHRyYW5zZm9ybT0icm90YXRlKDQ1KSI+PHBhdGggZD0ibTAgMGg1djIzaDIzdjVoLTI4eiIgb3BhY2l0eT0iLjQiLz48cGF0aCBkPSJtMSAxaDN2MjNoMjN2M2gtMjZ6IiBmaWxsPSIjZmZmIi8+PC9nPjwvc3ZnPg==');
}
.w-lightbox-right {
  display: none;
  right: 0;
  bottom: 0;
  /* <svg xmlns="http://www.w3.org/2000/svg" viewBox="-4 0 24 40" width="24" height="40"><g transform="rotate(45)"><path d="m0-0h28v28h-5v-23h-23z" opacity=".4"/><path d="m1 1h26v26h-3v-23h-23z" fill="#fff"/></g></svg> */
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii00IDAgMjQgNDAiIHdpZHRoPSIyNCIgaGVpZ2h0PSI0MCI+PGcgdHJhbnNmb3JtPSJyb3RhdGUoNDUpIj48cGF0aCBkPSJtMC0waDI4djI4aC01di0yM2gtMjN6IiBvcGFjaXR5PSIuNCIvPjxwYXRoIGQ9Im0xIDFoMjZ2MjZoLTN2LTIzaC0yM3oiIGZpbGw9IiNmZmYiLz48L2c+PC9zdmc+');
}
/*
 * Without specifying the with and height inside the SVG, all versions of IE render the icon too small.
 * The bug does not seem to manifest itself if the elements are tall enough such as the above arrows.
 * (http://stackoverflow.com/questions/16092114/background-size-differs-in-internet-explorer)
 */
.w-lightbox-close {
  right: 0;
  height: 2.6em;
  /* <svg xmlns="http://www.w3.org/2000/svg" viewBox="-4 0 18 17" width="18" height="17"><g transform="rotate(45)"><path d="m0 0h7v-7h5v7h7v5h-7v7h-5v-7h-7z" opacity=".4"/><path d="m1 1h7v-7h3v7h7v3h-7v7h-3v-7h-7z" fill="#fff"/></g></svg> */
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii00IDAgMTggMTciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxNyI+PGcgdHJhbnNmb3JtPSJyb3RhdGUoNDUpIj48cGF0aCBkPSJtMCAwaDd2LTdoNXY3aDd2NWgtN3Y3aC01di03aC03eiIgb3BhY2l0eT0iLjQiLz48cGF0aCBkPSJtMSAxaDd2LTdoM3Y3aDd2M2gtN3Y3aC0zdi03aC03eiIgZmlsbD0iI2ZmZiIvPjwvZz48L3N2Zz4=');
  background-size: 18px;
}
/**
 * 1. All IE versions add extra space at the bottom without this.
 */
.w-lightbox-strip {
  padding: 0 1vh;
  line-height: 0;
  /* 1 */
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
}
/*
 * 1. We use content-box to avoid having to do `width: calc(10vh + 2vw)`
 *    which doesn’t work in Safari anyway.
 * 2. Chrome renders images pixelated when switching to GPU. Making sure
 *    the parent is also rendered on the GPU (by setting translate3d for
 *    example) fixes this behavior.
 */
.w-lightbox-item {
  display: inline-block;
  width: 10vh;
  padding: 2vh 1vh;
  box-sizing: content-box;
  /* 1 */
  cursor: pointer;
  -webkit-transform: translate3d(0, 0, 0);
  /* 2 */
}
.w-lightbox-active {
  opacity: 0.3;
}
.w-lightbox-thumbnail {
  position: relative;
  height: 10vh;
  background: #222;
  overflow: hidden;
}
.w-lightbox-thumbnail-image {
  position: absolute;
  top: 0;
  left: 0;
}
.w-lightbox-thumbnail .w-lightbox-tall {
  top: 50%;
  width: 100%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}
.w-lightbox-thumbnail .w-lightbox-wide {
  left: 50%;
  height: 100%;
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
/*
 * Spinner
 *
 * Absolute pixel values are used to avoid rounding errors that would cause
 * the white spinning element to be misaligned with the track.
 */
.w-lightbox-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  margin-top: -20px;
  margin-left: -20px;
  border: 5px solid rgba(0, 0, 0, 0.4);
  border-radius: 50%;
  -webkit-animation: spin 0.8s infinite linear;
  animation: spin 0.8s infinite linear;
}
.w-lightbox-spinner:after {
  content: '';
  position: absolute;
  top: -4px;
  right: -4px;
  bottom: -4px;
  left: -4px;
  border: 3px solid transparent;
  border-bottom-color: #fff;
  border-radius: 50%;
}
/*
 * Utility classes
 */
.w-lightbox-hide {
  display: none;
}
.w-lightbox-noscroll {
  overflow: hidden;
}
@media (min-width: 60em) {
  .w-lightbox-content {
    height: 96vh;
    margin-top: 2vh;
  }
  .w-lightbox-view,
  .w-lightbox-view:before {
    height: 96vh;
  }
  /* .w-lightbox-content */
  .w-lightbox-group,
  .w-lightbox-group .w-lightbox-view,
  .w-lightbox-group .w-lightbox-view:before {
    height: 84vh;
  }
  .w-lightbox-image {
    max-width: 96vw;
    max-height: 96vh;
  }
  .w-lightbox-group .w-lightbox-image {
    max-width: 82.3vw;
    max-height: 84vh;
  }
  .w-lightbox-left,
  .w-lightbox-right {
    display: block;
    opacity: 0.5;
  }
  .w-lightbox-close {
    opacity: 0.8;
  }
  .w-lightbox-control:hover {
    opacity: 1;
  }
}
.w-lightbox-inactive,
.w-lightbox-inactive:hover {
  opacity: 0;
}
.w-richtext:before,
.w-richtext:after {
  content: ' ';
  display: table;
}
.w-richtext:after {
  clear: both;
}
.w-richtext ol,
.w-richtext ul {
  overflow: hidden;
}
.w-richtext .w-richtext-figure-selected.w-richtext-figure-type-video div:before,
.w-richtext .w-richtext-figure-selected[data-rt-type='video'] div:before {
  outline: 2px solid #2895f7;
}
.w-richtext .w-richtext-figure-selected.w-richtext-figure-type-image div,
.w-richtext .w-richtext-figure-selected[data-rt-type='image'] div {
  outline: 2px solid #2895f7;
}
.w-richtext figure.w-richtext-figure-type-video > div:before,
.w-richtext figure[data-rt-type='video'] > div:before {
  content: '';
  position: absolute;
  display: none;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.w-richtext figure {
  position: relative;
  max-width: 60%;
}
.w-richtext figure > div:before {
  cursor: default !important;
}
.w-richtext figure img {
  width: 100%;
}
.w-richtext figure figcaption.w-richtext-figcaption-placeholder {
  opacity: 0.6;
}
.w-richtext figure div {
  /* fix incorrectly sized selection border in the data manager */
  font-size: 0px;
  color: transparent;
}
.w-richtext figure.w-richtext-figure-type-image,
.w-richtext figure[data-rt-type='image'] {
  display: table;
}
.w-richtext figure.w-richtext-figure-type-image > div,
.w-richtext figure[data-rt-type='image'] > div {
  display: inline-block;
}
.w-richtext figure.w-richtext-figure-type-image > figcaption,
.w-richtext figure[data-rt-type='image'] > figcaption {
  display: table-caption;
  caption-side: bottom;
}
.w-richtext figure.w-richtext-figure-type-video,
.w-richtext figure[data-rt-type='video'] {
  width: 60%;
  height: 0;
}
.w-richtext figure.w-richtext-figure-type-video iframe,
.w-richtext figure[data-rt-type='video'] iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.w-richtext figure.w-richtext-figure-type-video > div,
.w-richtext figure[data-rt-type='video'] > div {
  width: 100%;
}
.w-richtext figure.w-richtext-align-center {
  margin-right: auto;
  margin-left: auto;
  clear: both;
}
.w-richtext figure.w-richtext-align-center.w-richtext-figure-type-image > div,
.w-richtext figure.w-richtext-align-center[data-rt-type='image'] > div {
  max-width: 100%;
}
.w-richtext figure.w-richtext-align-normal {
  clear: both;
}
.w-richtext figure.w-richtext-align-fullwidth {
  width: 100%;
  max-width: 100%;
  text-align: center;
  clear: both;
  display: block;
  margin-right: auto;
  margin-left: auto;
}
.w-richtext figure.w-richtext-align-fullwidth > div {
  display: inline-block;
  /* padding-bottom is used for aspect ratios in video figures
      we want the div to inherit that so hover/selection borders in the designer-canvas
      fit right*/
  padding-bottom: inherit;
}
.w-richtext figure.w-richtext-align-fullwidth > figcaption {
  display: block;
}
.w-richtext figure.w-richtext-align-floatleft {
  float: left;
  margin-right: 15px;
  clear: none;
}
.w-richtext figure.w-richtext-align-floatright {
  float: right;
  margin-left: 15px;
  clear: none;
}
.w-nav {
  position: relative;
  /*background: #dddddd;*/
  z-index: 1000;
}
.w-nav:before,
.w-nav:after {
  content: ' ';
  display: table;
}
.w-nav:after {
  clear: both;
}
.w-nav-brand {
  position: relative;
  float: left;
  text-decoration: none;
  color: #333333;
}
.w-nav-link {
  position: relative;
  display: inline-block;
  vertical-align: top;
  text-decoration: none;
  color: #222222;
  padding: 1em;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
}
.w-nav-link.w--current {
  color: #0082f3;
}
.w-nav-menu {
  position: relative;
  float: right;
}
.w--nav-menu-open {
  display: block !important;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: #c8c8c8;
  text-align: center;
  overflow: visible;
  min-width: 200px;
}
.w--nav-link-open {
  display: block;
  position: relative;
}
.w-nav-overlay {
  position: absolute;
  overflow: hidden;
  display: none;
  top: 100%;
  left: 0;
  right: 0;
  width: 100%;
}
.w-nav-overlay .w--nav-menu-open {
  top: 0;
}
.w-nav[data-animation='over-left'] .w-nav-overlay {
  width: auto;
}
.w-nav[data-animation='over-left'] .w-nav-overlay,
.w-nav[data-animation='over-left'] .w--nav-menu-open {
  right: auto;
  z-index: 1;
  top: 0;
}
.w-nav[data-animation='over-right'] .w-nav-overlay {
  width: auto;
}
.w-nav[data-animation='over-right'] .w-nav-overlay,
.w-nav[data-animation='over-right'] .w--nav-menu-open {
  left: auto;
  z-index: 1;
  top: 0;
}
.w-nav-button {
  position: relative;
  float: right;
  padding: 18px;
  font-size: 24px;
  display: none;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.w-nav-button.w--open {
  background-color: #c8c8c8;
  color: white;
}
.w-nav[data-collapse='all'] .w-nav-menu {
  display: none;
}
.w-nav[data-collapse='all'] .w-nav-button {
  display: block;
}
@media screen and (max-width: 75em) {
  .w-nav[data-collapse='medium'] .w-nav-menu {
    display: none;
  }
  .w-nav[data-collapse='medium'] .w-nav-button {
    display: block;
  }
}
@media screen and (max-width: 55em) {
  .w-nav[data-collapse='small'] .w-nav-menu {
    display: none;
  }
  .w-nav[data-collapse='small'] .w-nav-button {
    display: block;
  }
  .w-nav-brand {
    padding-left: 10px;
  }
}
@media screen and (max-width: 38.32em) {
  .w-nav[data-collapse='tiny'] .w-nav-menu {
    display: none;
  }
  .w-nav[data-collapse='tiny'] .w-nav-button {
    display: block;
  }
}
.w-tabs {
  position: relative;
}
.w-tabs:before,
.w-tabs:after {
  content: ' ';
  display: table;
}
.w-tabs:after {
  clear: both;
}
.w-tab-menu {
  position: relative;
}
.w-tab-link {
  position: relative;
  display: inline-block;
  vertical-align: top;
  text-decoration: none;
  padding: 9px 30px;
  text-align: left;
  cursor: pointer;
  color: #222222;
  background-color: #dddddd;
}
.w-tab-link.w--current {
  background-color: #c8c8c8;
}
.w-tab-content {
  position: relative;
  display: block;
  overflow: hidden;
}
.w-tab-pane {
  position: relative;
  display: none;
}
.w--tab-active {
  display: block;
}
@media screen and (max-width: 38.32em) {
  .w-tab-link {
    display: block;
  }
}
.w-ix-emptyfix:after {
  content: '';
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.w-dyn-empty {
  padding: 10px;
  background-color: #dddddd;
}
.w-dyn-bind-empty {
  display: none !important;
}
.w-condition-invisible {
  display: none !important;
}

/* CUSTOM CSS */

h1 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 38px;
  line-height: 44px;
  font-weight: 700;
}

p {
  margin-bottom: 10px;
  color: #95989a;
  font-size: 1.1em;
  line-height: 1.6em;
  font-weight: 300;
}

.terms-margin {
  margin-top: 15px;
}

.label-gray {
  color: #333;
}

.hero {
  height: 100vh;
  min-height: 900px;
  padding-top: 6vh;
  clear: right;
  background-image: -webkit-linear-gradient(
      270deg,
      rgba(52, 174, 255, 0.8),
      rgba(52, 174, 255, 0.8)
    ),
    url('../images/bg1.jpg');
  background-image: linear-gradient(
      180deg,
      rgba(52, 174, 255, 0.8),
      rgba(52, 174, 255, 0.8)
    ),
    url('../images/bg1.jpg');
  background-position: 0px 0px, 0px 0px;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
  background-attachment: scroll, fixed;
  color: #95989a;
  text-align: center;
}

.hero-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 1000px;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.hero-nav {
  float: none;
  text-align: center;
}

.hero-navlink {
  margin-right: 1%;
  margin-left: 1%;
  padding-top: 12px;
  padding-bottom: 12px;
  border: 1px solid transparent;
  color: #f0f0f0;
  font-size: 1.2em;
  font-weight: 300;
}

/* O número 2 é voltado ao menu de sidebar!! */
.hero-navlink2 {
  margin-right: 1%;
  margin-left: 1%;
  /* padding-top: 12px;
  padding-bottom: 12px; */
  border-bottom: 1.5px solid rgba(52, 174, 255, 0.8);
  color: rgba(52, 174, 255, 0.8);
  font-size: 1.2em;
  font-weight: 300;
  width: 100%;
  text-align: right;
  font-weight: bold;
}

.hero-navlink:hover {
  font-weight: 400;
}

.hero-navlink.w--current {
  color: #fff;
  font-weight: 400;
}

.body {
  background-color: #f0f0f0;
  font-family: Lato, sans-serif;
  bottom: 0px;
}

.hero-navbar {
  margin-bottom: 2%;
  background-color: transparent;
}

.hero-navbtn {
  padding-top: 12px;
  padding-bottom: 12px;
  border: 1px solid #fff;
  border-radius: 5px;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #f0f0f0;
  font-size: 1.2em;
  font-weight: 300;
}

.hero-navbtn:hover {
  background-color: #fff;
  color: #34aeff;
}

.hero-navbtn.blue {
  margin-left: 5px;
  border-color: #34aeff;
  color: #34aeff;
}

.hero-navbtn.blue:hover {
  background-color: #34aeff;
  color: #fff;
}

.whitelogo {
  width: 120px;
  margin-top: 2%;
  margin-bottom: 1%;
}

.hero-title {
  display: block;
  max-width: 600px;
  margin-top: 0px;
  margin-right: auto;
  margin-left: auto;
  color: #fff;
  font-weight: 400;
  letter-spacing: 1px;
}

.hero-subtitle {
  display: block;
  max-width: 500px;
  margin-right: auto;
  margin-bottom: 3%;
  margin-left: auto;
  color: #fff;
  font-size: 1.2em;
  line-height: 1.4em;
  font-weight: 300;
}

.arrow-down {
  display: block;
  width: 30px;
  margin-right: auto;
  margin-bottom: 2%;
  margin-left: auto;
}

.hero-box {
  margin: 0 2%;
  padding: 2% 3%;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 1px 5px 1px rgba(32, 32, 32, 0.2);
  text-align: left;
}

.hero-box .pointer {
  cursor: pointer;
}

.texto-cargo {
  color: #34aeff;
  font-size: 0.7em;
  text-transform: uppercase;
}

.box-title {
  margin-bottom: 20px;
  color: #34aeff;
  font-size: 1.9em;
  line-height: 1.1em;
}

.box-text {
  margin-bottom: 25px;
  font-size: 1.15em;
  line-height: 1.4em;
  font-weight: 300;
}

.button {
  text-decoration: none;
  display: block;
  width: 270px;
  margin-top: 25px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 5px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25);
  -webkit-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
  font-size: 1.3em;
  font-weight: 300;
  text-align: center;
  letter-spacing: 1px;
}

.button:focus {
  outline: none;
}

.button:hover {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25);
  opacity: 0.8;
  outline: none;
  transform: scale(0.99);
}

.button.turquoise {
  background-color: #55ddd2;
}

.button.turquoise.nomargin {
  margin-top: 0px;
}

.button.nomargin {
  margin-top: 0px;
}

.add-item-inverse-container .button.small-btn {
  display: block;
  width: 170px;
  padding: 12px 25px;
  border: 1px solid #55ddd2;
  background-color: #55ddd2;
  box-shadow: none;
  color: white;
  font-size: 1em;
  font-weight: 700;
}

.add-item-inverse-container .button.small-btn:hover {
  background-color: white;
  color: #55ddd2;
}

.button.small-btn {
  display: block;
  width: 200px;
  padding: 12px 25px;
  border: 1px solid #55ddd2;
  background-color: white;
  box-shadow: none;
  color: #55ddd2;
  font-size: 1em;
  font-weight: 700;
}

.button.small-btn:hover {
  background-color: #55ddd2;
  color: #fff;
}

.button.price-btn {
  display: block;
  width: 200px;
  margin-right: auto;
  margin-left: auto;
  padding: 12px 25px;
  border: 1px solid #55ddd2;
  background-color: transparent;
  box-shadow: none;
  color: #55ddd2;
  font-size: 0.9em;
  font-weight: 700;
}

.button.price-btn:hover {
  background-color: #55ddd2;
  color: #fff;
}

.button.price-btn-inverse {
  display: block;
  width: 200px;
  margin-right: auto;
  margin-left: auto;
  padding: 12px 25px;
  border: 1px solid #55ddd2;
  background-color: #55ddd2;
  box-shadow: none;
  color: #fff;
  font-size: 0.9em;
  font-weight: 700;
}

.button.price-btn-inverse:hover {
  background-color: #fff;
  color: #55ddd2;
}

.button.white-btn {
  display: block;
  margin-right: auto;
  margin-top: 5px;
  margin-left: auto;
  background-color: #fff;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.25);
  color: #55ddd2;
}

.button.white-btn:hover {
  background-color: #55ddd2;
  color: #fff;
}

@media screen and (max-width: 38.32em) {
  .button.filter-mobile-btn {
    display: block;
    margin-right: auto;
    margin-bottom: 15px;
    margin-left: auto;
    background-color: transparent;
    border: 2px solid #fff;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.25);
    color: #fff;
  }
  .button.filter-mobile-btn:hover {
    background-color: white;
    border: 2px solid #fff;
    color: #2895f7;
  }
}

.button.accont-btn--revisor {
  display: block;
  width: 300px;
  margin-right: auto;
  margin-left: auto;
  background-color: #55ddd2;
}

.button.accont-btn--revisor-nocolor {
  align-self: center;
  display: block;
  width: 300px;
  margin-right: auto;
  margin-left: auto;
}

.button.accont-btn--revisor.nomargin {
  margin-top: 0px;
}

.button.facebook-btn {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  border: 1px solid #3b5998;
  background-color: transparent;
  color: #3b5998;
  font-size: 1.1em;
  font-weight: 400;
}

.button.facebook-btn:hover {
  background-color: #3b5998;
  color: #fff;
}

.button.google-btn {
  width: 100%;
  margin-top: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  border: 1px solid #ea4335;
  background-color: transparent;
  color: #ea4335;
  font-size: 1.1em;
  font-weight: 400;
}

.button.google-btn:hover {
  background-color: #ea4335;
  color: #fff;
}

.button.accont-btn--redator {
  display: block;
  width: 300px;
  margin-right: auto;
  margin-left: auto;
  background-color: #34aeff;
}

.button.accont-btn--redator.nomargin {
  margin-top: 0px;
}

.button.create-redator {
  display: block;
  width: 180px;
  margin: 20px auto 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  border: 1px solid #34aeff;
  background-color: transparent;
  color: #34aeff;
  font-size: 0.9em;
  font-weight: 400;
}

.button.create-redator:hover {
  background-color: #34aeff;
  color: #fff;
}

.button.create-revisor {
  display: block;
  width: 180px;
  margin: 20px auto 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  border: 1px solid #55ddd2;
  background-color: transparent;
  color: #55ddd2;
  font-size: 0.9em;
  font-weight: 400;
}

.button.create-revisor:hover {
  background-color: #55ddd2;
  color: #fff;
}

.button.gray-btn {
  display: block;
  width: 250px;
  margin-right: auto;
  margin-left: auto;
  background-color: #95989a;
}

.button.gray-btn:hover {
  background-color: #34aeff;
}

.section {
  padding: 7% 10px;
  background-color: #fff;
}

.section-redator {
  padding: 180px 10px;
  background-color: #fff;
}

.stripe {
  height: 1px;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #f0f0f0;
}

.darkgray-text {
  font-size: 1.2em;
  text-align: center;
  letter-spacing: 1px;
}

.texto-redator {
  color: #34aeff;
  font-size: 0.7em;
  line-height: 1.2em;
  text-transform: uppercase;
}

.redator-boxtitle {
  margin-bottom: 20px;
  color: #34aeff;
  font-size: 1.6em;
  line-height: 1.1em;
}

.revisor-boxtitle {
  margin-bottom: 20px;
  color: #55ddd2;
  font-size: 1.6em;
  line-height: 1.1em;
}

.texto-revisor {
  color: #55ddd2;
  font-size: 0.7em;
  line-height: 1em;
  text-transform: uppercase;
}

.header {
  display: block;
  width: 600px;
  margin-right: auto;
  margin-bottom: 3%;
  margin-left: auto;
  text-align: center;
}

.redator-header {
  margin-top: 20px;
  color: #34aeff;
  font-size: 1.8em;
  line-height: 1em;
  font-weight: 300;
  letter-spacing: 1px;
}

.section-header {
  margin-top: 5px;
  color: #95989a;
  font-size: 2.3em;
  line-height: 1.3em;
  font-weight: 400;
}

.hero-btn {
  display: block;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 5px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25);
  font-size: 1.3em;
  font-weight: 300;
  text-align: center;
  letter-spacing: 1px;
  margin: 0 auto;
}

.hero-btn:hover {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25);
}

.hero-btn:active {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25),
    inset 0 0 8px 1px rgba(49, 49, 49, 0.2);
}

.hero-btn.turquesa {
  background-color: #55ddd2;
}

.hero-btn.danger {
  background-color: red;
}

.hero-btn a {
  color: #fff;
}

.hero-btn a:visited {
  color: #fff;
}

.blue-text {
  color: #34aeff;
  font-size: 1.3em;
  line-height: 1.5em;
  font-weight: 300;
}

.blue-text.bold {
  font-weight: 400;
}

.giant-text {
  color: #95989a;
  font-size: 2.5em;
  line-height: 1.5em;
}

.right-space {
  padding-right: 5%;
}

.row-container {
  display: block;
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
}

.card {
  position: relative;
  width: 23%;
  max-width: 280px;
  min-width: 219px;
  margin: 1%;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 2px 4px 1px hsla(204, 2%, 59%, 0.5);
}

.card .card-content-button {
  bottom: 2em;
  position: absolute;
  width: 100%;
}

.card.fullsize {
  width: 846px;
  max-width: auto;
  min-width: auto;
  margin: 0.83%;
}

.card-top {
  height: 130px;
  padding-top: 70px;
  padding-right: 25px;
  padding-left: 25px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-image: -webkit-linear-gradient(
      270deg,
      rgba(52, 174, 255, 0.7),
      rgba(52, 174, 255, 0.7)
    ),
    url('../images/bg2.svg');
  background-image: linear-gradient(
      180deg,
      rgba(52, 174, 255, 0.7),
      rgba(52, 174, 255, 0.7)
    ),
    url('../images/bg2.svg');
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
}

.card-avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.card-premium {
  position: relative;
  float: right;
}

.card-content {
  padding: 30px 25px 7em;
}

.card-btn {
  display: block;
  width: 140px;
  margin-top: 20px;
  margin-right: auto;
  margin-left: auto;
  border: 1px solid #55ddd2;
  border-radius: 5px;
  background-color: transparent;
  -webkit-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
  color: #55ddd2;
  text-align: center;
  text-transform: uppercase;
}

.card-btn:hover {
  background-color: #55ddd2;
  color: #fff;
}

.card--name {
  margin-bottom: 5px;
  color: #55ddd2;
  font-size: 1em;
}

.card--funcao {
  color: #95989a;
  font-size: 0.7em;
  line-height: 1.6em;
}

.card--local {
  margin-bottom: 16px;
  color: #95989a;
  font-size: 0.7em;
  line-height: 1.3em;
  font-weight: 300;
}

.card--preco {
  color: #95989a;
  font-size: 0.7em;
  line-height: 1.3em;
  font-weight: 300;
}

.card--valor {
  color: #34aeff;
  font-size: 1.3em;
}

.card--quant {
  color: #55ddd2;
  font-size: 1.7em;
  line-height: 1.1em;
  text-align: right;
}

.card--qualisupport {
  margin-bottom: 16px;
  color: #95989a;
  font-size: 0.7em;
  line-height: 1.3em;
  font-weight: 300;
  text-align: right;
}

.card--score {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: flex-end;
  font-family: 'Fontawesome webfont', sans-serif;
  color: #55ddd2;
  font-size: 1.5em;
  line-height: 1.1em;
  text-align: right;
}

.card--user {
  color: #95989a;
  font-size: 0.8em;
  line-height: 1.3em;
  font-weight: 300;
  text-align: right;
}

.card--package {
  color: #ffce2b;
  font-size: 0.8em;
  line-height: 1.3em;
  font-weight: 300;
  text-align: right;
}

.score-icon {
  color: #ffce2b;
  font-size: 0.7em;
  margin: 0 1em;
}

.post-hero {
  height: 15%;
}

.hero-row {
  display: block;
  max-width: 950px;
  margin-right: auto;
  margin-left: auto;
}

.section-dark {
  padding-top: 6%;
  padding-bottom: 6%;
  background-color: #f0f0f0;
}

.section-dark.img-bg {
  background-image: url('../images/howto.svg'), url('../images/howto.svg');
  background-position: 117% 50%, -211px 50%;
  background-size: 400px, 400px;
  background-repeat: no-repeat, no-repeat;
}

.section-dark.light-padding {
  padding: 2%;
}

.section-dark.full-height {
  height: 100%;
}

.process-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 900px;
  margin-right: auto;
  margin-left: auto;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.progress-item {
  width: 33%;
  padding: 25px 30px;
  color: #666;
  text-align: center;
}

.received-item {
  width: 33%;
  padding: 25px 30px;
  color: #666;
  text-align: center;
}

.btn-received {
  margin: 0 auto;
  margin-top: 4%;
}

.progress-number {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 90px;
  height: 90px;
  margin-right: auto;
  margin-bottom: 15px;
  margin-left: auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 1px 1px 4px 0 rgba(53, 53, 53, 0.2);
  color: #34aeff;
  font-size: 2.5em;
}

.progress-number.color {
  color: #55ddd2;
}

.turquoise-text {
  color: #34aeff;
  font-size: 1.3em;
  line-height: 1.5em;
  font-weight: 300;
}

.turquoise-text.bold {
  font-weight: 400;
}

.revisor-header {
  margin-top: 20px;
  color: #55ddd2;
  font-size: 1.8em;
  line-height: 1.2em;
  font-weight: 300;
  letter-spacing: 1px;
}

.revisor-header.bold {
  margin-bottom: 20px;
  font-weight: 700;
}

.container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 1000px;
  margin-right: auto;
  margin-left: auto;
}

.container.wrap-container {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.container.all-center {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.halfbox {
  width: 50%;
  padding: 2%;
}

.halfbox.right-space {
  max-width: 75%;
  padding-right: 8%;
}

/* .halfbox.overflow {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 400px;
  background-image: url('../images/img-reviewer-preview.png');
  background-position: center center;
  background-size: cover;
} */

.halfbox.left-space {
  padding-left: 8%;
}

.halfbox.revisor-img {
  background-image: url('../images/bg1.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 300px;
}

.halfbox.turquoise-box {
  position: relative;
  padding: 5%;
  background-color: #55ddd2;
  color: #fff;
}

.halfbox.white-box {
  position: relative;
  padding: 8%;
  background-color: #fff;
  color: #fff;
}

.halfbox.interprise-img {
  background-image: url('../images/bg2.svg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.halfbox.blue-box {
  position: relative;
  padding: 5%;
  background-color: #34aeff;
  color: #fff;
}

.halfbox.overlay-turquoise {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: -webkit-linear-gradient(
      270deg,
      rgba(85, 221, 210, 0.85),
      rgba(85, 221, 210, 0.85)
    ),
    url('../images/bg1.jpg');
  background-image: linear-gradient(
      180deg,
      rgba(85, 221, 210, 0.85),
      rgba(85, 221, 210, 0.85)
    ),
    url('../images/bg1.jpg');
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
}

.halfbox.accont-box {
  position: relative;
  padding: 6% 8%;
  background-color: #fff;
  color: #fff;
}

.halfbox.overlay-revisor {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: -webkit-linear-gradient(
      270deg,
      rgba(85, 221, 210, 0.85),
      rgba(85, 221, 210, 0.85)
    ),
    url('../images/bg1.jpg');
  background-image: linear-gradient(
      180deg,
      rgba(85, 221, 210, 0.85),
      rgba(85, 221, 210, 0.85)
    ),
    url('../images/bg1.jpg');
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
}

.halfbox.overlay-redator {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: -webkit-linear-gradient(
      270deg,
      rgba(52, 174, 255, 0.85),
      rgba(52, 174, 255, 0.85)
    ),
    url('../images/bg1.jpg');
  background-image: linear-gradient(
      180deg,
      rgba(52, 174, 255, 0.85),
      rgba(52, 174, 255, 0.85)
    ),
    url('../images/bg1.jpg');
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
}

.halfbox.overlay-login {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: -webkit-linear-gradient(
      270deg,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.2)
    ),
    url('../images/bg1.jpg');
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.2)
    ),
    url('../images/bg1.jpg');
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
}

.halfbox.login-box {
  position: relative;
  padding: 3%;
  background-color: #fff;
  color: #fff;
}

.login-box .error {
  width: 300px;
}

.large-box {
  width: 50%;
}

.large-box.right-space {
  width: 40%;
  padding-right: 6%;
}

.large-box.overflow {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
}

.white-box {
  padding: 6%;
  border-radius: 5px;
  background-color: #fff;
}

.pre-title {
  margin-top: 15px;
  margin-bottom: 0px;
  font-size: 0.75em;
  line-height: 1.5em;
  text-align: center;
  flex: 1;
}

.pre-title.bold {
  font-size: 1.2em;
  font-weight: 400;
  text-align: center;
}

.pre-title.bold-turquoise {
  color: #55ddd2;
  font-size: 1.2em;
  font-weight: 400;
}

.testemonial-person {
  position: relative;
  bottom: 17px;
  display: block;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}

.testemonial-photo {
  width: 80px;
  border-radius: 50%;
}

.testemonial-photo.avaliation-photo {
  width: 60px;
  margin-right: 20px;
}

.subtitle {
  font-size: 1em;
}

.inbetween {
  margin-bottom: 0px;
  padding: 1% 2%;
}

.footer {
  padding-top: 4%;
  padding-bottom: 4%;
  background-color: #34aeff;
  background-position: 107% 69px;
  background-size: contain;
  background-repeat: no-repeat;
  bottom: 0px;
}

.large-container {
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.large-container.flexcourners {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 1200px;
  margin-bottom: 2%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.25);
}

.large-container.price-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 1%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.large-container.centered-text {
  text-align: center;
}

/* Not used anymore */
.large-container.flex-grid {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 1200px;
  margin-bottom: 2%;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-content: space-around;
  -ms-flex-line-pack: distribute;
  align-content: space-around;
}

.large-container.grid {
  max-width: 1200px;
  margin-bottom: 2%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  grid-auto-rows: auto;
  grid-gap: 1.5em;
  align-items: stretch;
  justify-items: center;
}

.large-container.flex-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 1200px;
  margin-bottom: 2%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.25);
}

.footer-link {
  display: block;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #fff;
  font-size: 1.1em;
  font-weight: 300;
  letter-spacing: 1px;
  text-decoration: none;
}

.footer-text {
  display: block;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #fff;
  font-size: 1.1em;
  font-weight: 300;
  letter-spacing: 1px;
  text-decoration: none;
}

.logo-footer {
  width: 120px;
}

.menu-container {
  max-width: 2000px;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.navlink {
  margin-right: 5px;
  margin-left: 5px;
  padding: 12px 15px;
  border: 1px solid transparent;
  color: #34aeff;
  font-size: 1.2em;
  font-weight: 300;
}

.navlink:hover {
  font-weight: 400;
}

.navlink.w--current {
  color: #34aeff;
  font-weight: 400;
}

.brand {
  margin-left: 20px;
  float: left;
}

.navbar {
  position: relative;
  min-height: 50px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  padding-top: 15px;
  padding-bottom: 15px;
  clear: both;
  background-color: #fff;
  box-shadow: 0 2px 6px 0 rgba(48, 48, 48, 0.3);
}

.nav-container {
  display: block;
  width: 90vw;
  margin-right: 0;
  margin-left: 15%;
  padding-right: 2%;
  padding-left: 2%;
  clear: both;
}

.nav-menu {
  padding-top: 8px;
  float: right;
  width: 75%;
}

.contexto-img {
  width: 150px;
  margin-bottom: 20px;
}

.scroll {
  position: absolute;
  right: 5%;
  bottom: 7%;
}

.image {
  height: 50px;
}

.block-mainheader {
  margin-top: 5px;
  color: #fff;
  font-size: 2.4em;
  line-height: 1.3em;
  font-weight: 400;
}

.bullet-ul {
  width: 150px;
  margin-top: 20px;
}

.bullet {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 18px;
  border-radius: 50%;
  background-color: #fff;
}

.white-halfbox {
  width: 48%;
  margin-top: 3%;
  padding: 6% 8%;
  background-color: #fff;
}

.white-halfbox.right-space {
  width: 50%;
  padding-right: 8%;
}

.white-halfbox.overflow {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.white-halfbox.left-space {
  padding-left: 8%;
}

.white-halfbox.revisor-img {
  background-image: url('../images/bg1.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.white-halfbox.turquoise-box {
  position: relative;
  padding: 5%;
  background-color: #55ddd2;
  color: #fff;
}

.centered-img {
  display: block;
  height: 130px;
  margin-right: auto;
  margin-bottom: 30px;
  margin-left: auto;
}

.one-fourth {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 33%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.table-desc {
  padding-top: 10%;
  padding-right: 10%;
  padding-left: 5%;
  color: #95989a;
}

.hidden-display {
  display: none;
}

.price-box {
  display: block;
  cursor: pointer;
  max-width: 270px;
  margin-right: auto;
  margin-bottom: 40px;
  margin-left: auto;
  margin-top: 5px;
  padding-top: 8%;
  padding-bottom: 10%;
  border-radius: 5px;
  text-align: center;
  background-color: #fff;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
}

.price-box:hover {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
}

.price-box.become-reviewer-price {
  width: 330px;
  height: 100%;
  max-width: none;
}

.price-box.become-reviewer-price ul li {
  text-align: left;
  font-size: 1em;
  color: #95989a;
  padding: 5px 0;
}

.stripe-turquoise {
  height: 1px;
  margin: 20px;
  background-color: #55ddd2;
}

.price-billied {
  color: #3b5998;
  font-size: 0.4em;
}

.price-p {
  padding-right: 20px;
  padding-left: 20px;
}

.price-item {
  height: 40px;
  padding: 10px;
  border-right: 1px solid #55ddd2;
  border-bottom: 1px solid #55ddd2;
  text-align: center;
}

.price-item.top {
  border-right-style: none;
}

.price-item.corner {
  border-right-style: none;
  text-align: left;
}

.price-item.left {
  text-align: left;
}

.price-item.right {
  border-right-style: none;
}

.little-p {
  margin-bottom: 0px;
  font-size: 0.9em;
  font-weight: 400;
}

.little-p.bold {
  font-size: 1.2em;
  font-weight: 400;
}

.little-p.bold-turquoise {
  color: #55ddd2;
  font-size: 1.2em;
  font-weight: 400;
}

.little-p.darkblue {
  color: #3d8b85;
}

.little-p.darkblue.mobile-only {
  display: none;
}

.little-p.standard-text {
  color: #55ddd2;
}

.little-p.mobile-only {
  display: none;
}

.price-img {
  height: 24px;
}

.price-list {
  margin-bottom: 20px;
}

.button-box {
  height: 50px;
  margin-top: 25px;
}

.result-number {
  color: #34aeff;
  font-size: 1.4em;
  font-weight: 700;
}

.heading {
  width: 60%;
  font-size: 1.6em;
  font-weight: 400;
  text-align: center;
}

.overlay-text {
  width: 80%;
  color: #fff;
  font-size: 1.8em;
  line-height: 1.4em;
  font-weight: 400;
  text-align: center;
}

.account-top {
  display: block;
  color: #55ddd2;
}

.turquoise-list {
  display: inline-block;
  margin-right: auto;
  margin-left: auto;
  padding: 10px;
  font-family: Fontawesome, sans-serif;
  color: #55ddd2;
  font-size: 1.2em;
}

.step-revisor {
  display: inline-block;
  width: 22px;
  height: 22px;
  margin-right: 3px;
  margin-left: 3px;
  padding-top: 1px;
  border-radius: 50%;
  background-color: rgba(85, 221, 210, 0.4);
  color: #fff;
  font-size: 0.8em;
  text-align: center;
  cursor: pointer;
}

.step-revisor:hover {
  background-color: #55ddd2;
}

.step-revisor.active {
  background-color: #55ddd2;
}

.unordered-list {
  display: block;
  margin-right: auto;
  margin-bottom: 0px;
  margin-left: auto;
  text-align: right;
}

.form {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: block;
  margin-right: auto;
  margin-bottom: 10px;
  margin-left: auto;
}

.accont-input {
  display: block;
  width: 300px;
  margin-right: auto;
  margin-bottom: 15px;
  margin-left: auto;
  padding-left: 5px;
  border-bottom-width: 2px;
  border-bottom-color: #f0f0f0;
  background-image: -webkit-linear-gradient(270deg, #fff, #fff);
  background-image: linear-gradient(180deg, #fff, #fff);
  color: #95989a;
  font-size: 1.1em;
  font-weight: 300;
}

.separator {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 300px;
  margin-top: 20px;
  margin-right: auto;
  margin-left: auto;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #95989a;
  font-weight: 300;
}

.account-stripe {
  width: 30%;
  height: 2px;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #f0f0f0;
}

.account-linkbox {
  display: block;
  width: 320px;
  margin-right: auto;
  margin-left: auto;
}

.gender {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  margin: 20px auto 10px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  color: #95989a;
  font-size: 0.9em;
  font-weight: 700;
}

.gender-radio {
  display: flex;
  margin-right: 15px;
  font-size: 1.1em;
  font-weight: 400;
  gap: 5px;
}

.gender-box {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.gender-box > label {
  color: #95989a;
  font-size: 1.2em;
  font-weight: 300;
  padding-left: 5px;
}

.gender > .gender-box:first-child .gender-radio {
  margin-left: 8px;
}

.gender-radio {
  padding-top: 8px;
}

.top-demonstration {
  height: 110px;
  padding-top: 50px;
  padding-right: 25px;
  padding-left: 25px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-image: -webkit-linear-gradient(
      270deg,
      rgba(52, 174, 255, 0.7),
      rgba(52, 174, 255, 0.7)
    ),
    url('../images/bg2.svg');
  background-image: linear-gradient(
      180deg,
      rgba(52, 174, 255, 0.7),
      rgba(52, 174, 255, 0.7)
    ),
    url('../images/bg2.svg');
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
}

.demonstratrion {
  position: relative;
  display: block;
  width: 300px;
  max-width: auto;
  min-width: auto;
  margin: 1% auto;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 2px 4px 1px hsla(204, 2%, 59%, 0.5);
}

.avatar-tooltip {
  position: absolute;
  left: -67px;
  bottom: 28px;
  width: 100px;
  padding: 10px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.75);
  font-size: 0.9em;
  line-height: 1.2em;
}

.capa-tooltip {
  position: absolute;
  left: 232px;
  top: 18px;
  width: 105px;
  padding: 10px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.75);
  font-size: 0.9em;
  line-height: 1.2em;
}

.account-link.w--current {
  color: #55ddd2;
  text-decoration: none;
}

.account-link.w--current:hover {
  color: #fff;
}

.div-block {
  position: absolute;
  left: 0px;
  top: 0px;
}

.blue-list {
  display: inline-block;
  margin-right: auto;
  margin-left: auto;
  padding: 10px;
  font-family: Fontawesome, sans-serif;
  color: #34aeff;
  font-size: 1.2em;
}

.step-redator {
  display: inline-block;
  width: 22px;
  height: 22px;
  margin-right: 3px;
  margin-left: 3px;
  padding-top: 1px;
  border-radius: 50%;
  background-color: rgba(52, 174, 255, 0.5);
  color: #fff;
  font-size: 0.8em;
  text-align: center;
  cursor: pointer;
}

.step-redator:hover {
  background-color: #34aeff;
}

.step-redator.active {
  background-color: #34aeff;
}

.gray-list {
  display: inline-block;
  margin-right: auto;
  margin-left: auto;
  padding: 10px;
  font-family: Fontawesome, sans-serif;
  color: #95989a;
  font-size: 1.2em;
}

.toggle-login {
  padding-top: 3%;
  padding-bottom: 3%;
  border-top: 1px solid #f0f0f0;
  color: #95989a;
  font-size: 1.2em;
  line-height: 1.4em;
  font-weight: 300;
  text-align: center;
}

.text-block-3 {
  line-height: 1.2em;
}

.toggle-link {
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #34aeff;
  font-weight: 400;
  text-decoration: none;
  text-transform: uppercase;
}

.toggle-esqueci-senha {
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #34aeff;
  font-weight: 400;
  text-decoration: none;
}

.toggle-link:hover {
  color: #95989a;
}

.account-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.account-halfleft {
  width: 50%;
  padding: 3% 6%;
  border-top: 1px solid #f0f0f0;
  border-right: 1px solid #f0f0f0;
  color: #34aeff;
  font-size: 1.4em;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.account-halfright {
  width: 50%;
  padding: 3% 6%;
  border-top: 1px solid #f0f0f0;
  color: #55ddd2;
  font-size: 1.4em;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.redator-img {
  margin: 0 auto 10px;
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-content: center;
  border-radius: 40px;
  background: white;
  box-shadow: 1px 2px 6px 1px #ccc;
}

.redator-img > * {
  align-self: center;
}

.testemonial-name {
  margin-bottom: 0px;
  font-size: 1.1em;
  line-height: 1.5em;
  font-weight: 400;
  text-align: center;
}

.testemonial-name.bold {
  font-size: 1.2em;
  font-weight: 400;
}

.testemonial-name.bold-turquoise {
  color: #55ddd2;
  font-size: 1.2em;
  font-weight: 400;
}

.two-thirds {
  width: 60%;
  padding-right: 1.5%;
}

.one-third {
  width: 40%;
  padding-left: 1.5%;
}

.perfil-box {
  margin-bottom: 3%;
  padding: 8%;
  background-color: #fff;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.2);
}

.price-text {
  margin-top: 15px;
  margin-bottom: 0px;
  font-size: 0.75em;
  line-height: 1.5em;
  text-align: center;
}

.price-text.bold {
  font-size: 1.2em;
  font-weight: 400;
  text-align: left;
}

.price-text.bold-turquoise {
  color: #55ddd2;
  font-size: 1.2em;
  font-weight: 400;
}

.perfil-video {
  margin-bottom: 30px;
}

.perfil-list {
  padding-top: 10px;
  padding-bottom: 1px;
  border-left: 1px solid #55ddd2;
}

.perfil-list.no-border {
  border-left-style: none;
}

.list-style {
  width: 10px;
  height: 10px;
  margin-right: 15px;
  padding: 10px;
  border-radius: 50%;
  background-color: #55ddd2;
}

.nopadding {
  padding-right: 0px;
  padding-left: 0px;
}

.perfil-especification {
  margin-bottom: 10px;
  font-size: 1.2em;
  line-height: 1.3em;
  font-weight: 400;
}

.perfil-especification.listonly {
  margin-bottom: 0px;
  color: #3d8b85;
  text-decoration: none;
}

.perfil-especification.listonly:hover {
  color: #95989a;
}

.perfil-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 15px;
  margin-left: -10px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.perfil-item.aligner-item {
  margin-bottom: 20px;
  margin-left: 0px;
}

.filter-form {
  display: block;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  background-color: #f5f5f5;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.25);
}

.filter-top {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.filter-btn {
  width: 55px;
  height: 50px;
  padding-top: 11px;
  background-color: #fff;
  background-image: url('../images/lupa.svg');
  background-position: 50% 45%;
  background-size: 21px;
  background-repeat: no-repeat;
  font-family: 'Fontawesome webfont', sans-serif;
  color: #95989a;
}

.filter-btn:hover {
  background-image: url('../images/lupa.svg'),
    -webkit-linear-gradient(270deg, #f0f0f0, #f0f0f0);
  background-image: url('../images/lupa.svg'),
    linear-gradient(180deg, #f0f0f0, #f0f0f0);
  background-position: 50% 50%, 0px 0px;
  background-size: 21px, auto;
  background-repeat: no-repeat, repeat;
}

.close-btn {
  text-align: center;
  line-height: 28px;
  background: none;
  background-color: #fff;
}

.close-btn:hover {
  background: none;
  background-color: #f0f0f0;
  line-height: 33px;
}

.filter-input {
  height: 50px;
  margin-bottom: 0px;
  padding-left: 35px;
  border-style: none;
  font-size: 1.1em;
}

.open-filter {
  width: 70px;
  height: 50px;
  padding-top: 11px;
  background-color: transparent;
  background-image: url('../images/filter.svg');
  background-position: 50% 50%;
  background-size: 21px;
  background-repeat: no-repeat;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  font-family: 'Fontawesome webfont', sans-serif;
  color: #95989a;
  text-align: center;
}

.open-filter:hover {
  background-image: url('../images/filter.svg'),
    -webkit-linear-gradient(270deg, #fff, #fff);
  background-image: url('../images/filter.svg'),
    linear-gradient(180deg, #fff, #fff);
  background-position: 50% 55%, 0px 0px;
  background-size: 21px, auto;
  background-repeat: no-repeat, repeat;
}

.filter-dropdown {
  display: none;
  width: 95%;
  padding: 15px 10px 32px;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  opacity: 0;
  -webkit-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}

.filter-dropdown-app {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 100%;
  padding: 15px 10px 15px;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}

.filter-item {
  display: inline-block;
  width: 12em;
  padding: 0 10px;
}

.filter-sep {
  border-right: 1px solid hsla(204, 2%, 59%, 0.5);
}

.filter-item.last-item {
  padding-right: 0px;
  border-right-style: none;
  padding-bottom: 3% !important;
}

.filter-title {
  margin-bottom: 5px;
  color: #95989a;
  font-size: 0.9em;
  text-align: center;
}

button.filter-button {
  width: 100%;
}

.support-text {
  margin-top: 5px;
  margin-bottom: 5px;
  color: #95989a;
  font-size: 0.9em;
  text-align: center;
}

.stars-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.star-medium {
  width: 15%;
  height: 20px;
  margin-right: 10px;
}

.form-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.filter-select {
  height: 42px;
  border-style: none;
  border-radius: 0px;
  background-image: -webkit-linear-gradient(270deg, #fff, #fff);
  background-image: linear-gradient(180deg, #fff, #fff);
  color: #95989a;
}

.form-3 {
  background-color: #f7f7f7;
}

.info-boxes {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 1200px;
  margin-right: auto;
  margin-bottom: 2%;
  margin-left: auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.info-boxes.flexcourners {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 1200px;
  margin-bottom: 2%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.25);
}

.info-boxes.price-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 1%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.info-boxes.centered-text {
  text-align: center;
}

.info-boxes.flex-grid {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 1200px;
  margin-bottom: 2%;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-content: space-around;
  -ms-flex-line-pack: distribute;
  align-content: space-around;
}

.info-boxes.flex-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 1200px;
  margin-bottom: 2%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.25);
}

.badge {
  width: 150px;
  height: 150px;
  padding: 25px;
  border: 1px solid #55ddd2;
  border-radius: 5px;
  color: #95989a;
  font-size: 0.9em;
  line-height: 1em;
  font-weight: 300;
  text-align: center;
}

.badge-vert {
  width: 60px;
  margin-bottom: 5px;
}

.badge2 {
  width: 60px;
}

.badge-hor {
  width: 60px;
  margin-bottom: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.perfil-sidebar {
  margin-bottom: 3%;
  background-color: #fff;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.2);
}

.side-section {
  padding: 8%;
}

.sidebar-price {
  padding-top: 12px;
  padding-bottom: 12px;
  border-top: 1px solid #55ddd2;
  border-bottom: 1px solid #55ddd2;
  text-align: center;
}

.small-paragraph {
  font-size: 14px;
  line-height: 1.3em;
  font-style: italic;
}

.sidelink {
  color: #34aeff;
}

.sidelink.w--current {
  color: #34aeff;
  text-decoration: none;
}

.sidelink.w--current:hover {
  color: #95989a;
}

.stars-testemonial {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.side-publicity {
  margin-bottom: 3%;
  padding: 8% 10%;
  background-color: #fff;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.2);
  text-align: center;
}

.publicidade {
  margin-bottom: 2px;
  color: #34aeff;
  font-size: 0.9em;
  line-height: 1.2em;
}

.publicity-text {
  margin-top: 10px;
  margin-bottom: 10px;
  color: #55ddd2;
  font-size: 2.5em;
  line-height: 1.1em;
  font-weight: 300;
  letter-spacing: 1px;
}

.publicity-text.bold {
  margin-bottom: 20px;
  font-weight: 700;
}

.publicity-descont {
  margin-top: 10px;
  margin-bottom: 10px;
  color: #95989a;
  font-size: 3.5em;
  line-height: 1.1em;
  font-weight: 400;
  letter-spacing: 1px;
}

.publicity-descont.bold {
  margin-bottom: 20px;
  font-weight: 700;
}

.perfil-top {
  height: 300px;
  padding-top: 12%;
  background-image: -webkit-linear-gradient(
      270deg,
      rgba(85, 221, 210, 0.85),
      rgba(85, 221, 210, 0.85)
    ),
    url('../images/bg1.jpg');
  background-image: linear-gradient(
      180deg,
      rgba(85, 221, 210, 0.85),
      rgba(85, 221, 210, 0.85)
    ),
    url('../images/bg1.jpg');
  background-position: 0px 0px, 100% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
}

.perfil-avatar {
  width: 200px;
  height: 170px;
  border-radius: 90px;
  margin-right: 2em;
}

.perfil-info {
  margin-bottom: 3%;
  padding-top: 4%;
  padding-bottom: 4%;
  padding-left: 2%;
  background-color: #fff;
}

.flex-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.star-icon {
  font-family: 'Fontawesome webfont', sans-serif;
  color: #ffce2b;
}

.giant-text-turquoise {
  color: #55ddd2;
  font-size: 2.4em;
  line-height: 1em;
}

.perfil-function {
  margin-bottom: 0px;
  font-size: 1.2em;
  line-height: 1.3em;
  font-weight: 400;
}

.perfil-function.listonly {
  margin-bottom: 0px;
  color: #3d8b85;
  text-decoration: none;
}

.perfil-function.listonly:hover {
  color: #95989a;
}

.perfil-name {
  margin-bottom: 10px;
  color: #55ddd2;
  font-size: 1.5em;
  line-height: 1.5em;
  font-weight: 400;
}

.perfil-name.bold {
  font-weight: 400;
}

.skills {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 5px 10px;
  border: 1px solid #55ddd2;
  border-radius: 5px;
  color: #95989a;
  font-size: 12px;
  font-weight: 300;
}

.quant {
  display: inline-block;
  font-size: 1.4em;
  text-align: center;
}

.quanti-numbers {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-evenly;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.three-fifth {
  width: 60%;
  padding-right: 1.5%;
}

.two-fifth {
  width: 40%;
  padding-left: 1.5%;
}

.close-filter {
  display: none;
  opacity: 0;
}

@media (max-width: 75em) {
  .hero {
    padding-top: 3vh;
  }
  .hero-navlink {
    padding-right: 8px;
    padding-left: 8px;
    font-size: 1em;
  }
  .hero-box {
    margin: 3%;
    padding-right: 3%;
    padding-left: 3%;
  }
  .box-text {
    font-size: 1.05em;
  }
  .button {
    width: auto;
    font-size: 1.1em;
  }
  .button.price-btn {
    width: 90%;
    font-size: 0.7em;
  }
  .button.price-btn-inverse {
    width: 90%;
    font-size: 0.7em;
  }
  .button.gray-btn {
    width: auto;
  }
  .redator-boxtitle {
    font-size: 1.5em;
  }
  .revisor-boxtitle {
    font-size: 1.5em;
  }
  .hero-btn {
    font-size: 1.2em;
  }
  .giant-text {
    font-size: 2em;
  }
  .hero-row {
    max-width: 100%;
  }
  .section-dark.img-bg {
    padding-bottom: 13%;
    background-image: url('../images/howto.svg');
    background-position: -34px 106%;
    background-size: 200px;
    background-repeat: no-repeat;
  }
  .halfbox.right-space {
    padding-right: 4%;
  }
  .halfbox.overflow {
    overflow: hidden;
  }
  .halfbox.left-space {
    padding-left: 4%;
  }
  .halfbox.accont-box {
    padding-right: 3%;
    padding-left: 3%;
  }
  .halfbox.login-box {
    padding-right: 3%;
    padding-left: 3%;
  }
  .menu-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .menu-mobile {
    padding-top: 15px;
    padding-bottom: 10px;
    background-color: #fff;
    color: #34aeff;
    font-size: 2em;
  }
  .menu-mobile.w--open {
    background-color: transparent;
    color: #34aeff;
  }
  .navlink {
    padding-right: 6px;
    padding-left: 6px;
    color: #fff;
    font-size: 1em;
  }
  .navlink.w--current {
    color: #fff;
    font-weight: 700;
  }
  .nav-menu {
    background-color: #34aeff;
  }
  .white-halfbox.right-space {
    padding-right: 4%;
  }
  .white-halfbox.overflow {
    overflow: hidden;
  }
  .white-halfbox.left-space {
    padding-left: 4%;
  }
  .price-box {
    max-width: auto;
    margin-right: 5px;
    margin-left: 5px;
  }
  .price-p {
    padding-right: 10px;
    padding-left: 10px;
    font-size: 1em;
  }
  .price-item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 44px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .price-item.corner {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .price-item.left {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .little-p.darkblue {
    line-height: 1.2em;
  }
  .row {
    width: auto;
  }
  .home-menu {
    padding-top: 15px;
    padding-bottom: 10px;
    background-color: #fff;
    color: #34aeff;
    font-size: 2em;
  }
  .home-menu.w--open {
    background-color: transparent;
    color: #34aeff;
  }
  .perfil-top {
    height: 250px;
  }
  .giant-text-turquoise {
    font-size: 2em;
  }
}

@media (max-width: 55em) {
  .hero {
    padding-top: 1vh;
  }
  .whitelogo {
    width: 80px;
    margin-top: 0%;
    float: left;
  }
  .button {
    width: 230px;
    font-size: 1.1em;
  }
  .button.price-btn {
    width: 92%;
    padding-right: 10px;
    padding-left: 10px;
    font-size: 0.6em;
  }
  .button.price-btn-inverse {
    padding-right: 10px;
    padding-left: 10px;
    font-size: 0.6em;
  }
  .header {
    width: 500px;
  }
  .redator-header {
    font-size: 1.6em;
  }
  .section-header {
    font-size: 1.8em;
  }
  .giant-text {
    font-size: 1.5em;
  }
  .container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .container.inverse {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .halfbox {
    display: block;
    width: 90%;
    margin-right: auto;
    margin-left: auto;
  }
  .halfbox.viral {
    width: 100%;
  }
  .footer {
    background-image: none;
    background-position: 0px 0px;
    background-size: auto;
    background-repeat: repeat;
  }
  .container-2 {
    float: right;
  }
  .menu-mobile {
    padding-top: 10px;
    color: #34aeff;
    font-size: 2em;
  }
  .block-mainheader {
    font-size: 1.8em;
  }
  .white-halfbox {
    display: block;
    width: 90%;
    margin-right: auto;
    margin-left: auto;
  }
  .row {
    width: 350px;
  }
  .home-menu {
    padding-top: 10px;
    color: #34aeff;
    font-size: 2em;
  }
  .flex-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .giant-text-turquoise {
    font-size: 1.5em;
  }
  .skills {
    margin-top: 10px;
    margin-bottom: 0px;
  }
  .three-fifth {
    width: 100%;
  }
  .two-fifth {
    width: 90%;
    margin-top: 2%;
  }
}

@media (max-width: 600px) {
  .hero {
    height: auto;
    padding-top: 0vh;
    background-image: -webkit-linear-gradient(
        270deg,
        rgba(52, 174, 255, 0.8),
        rgba(52, 174, 255, 0.8)
      ),
      url('../images/bg1.jpg');
    background-image: linear-gradient(
        180deg,
        rgba(52, 174, 255, 0.8),
        rgba(52, 174, 255, 0.8)
      ),
      url('../images/bg1.jpg');
    background-position: 0px 0px, 50% 50%;
    background-size: auto, auto;
  }
  .hero-container {
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .hero-nav {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
    background-color: #34aeff;
    color: #fff;
    text-align: left;
  }
  .hero-navlink.w--current {
    color: #fff;
    font-size: 1.2em;
    font-weight: 700;
  }
  .hero-navbar {
    margin-bottom: 5%;
    padding: 6% 3% 3% 6%;
  }
  .hero-navbtn {
    display: block;
    margin-left: 6%;
    background-color: #fff;
    color: #34aeff;
  }
  .hero-navbtn.blue {
    display: block;
    border-radius: 0px;
    background-color: #fff;
  }
  .whitelogo {
    width: 60px;
  }
  .hero-title {
    padding-right: 13px;
    padding-left: 13px;
    font-size: 1.5em;
    line-height: 1.5em;
  }
  .hero-subtitle {
    margin-bottom: 4%;
    padding-right: 12px;
    padding-left: 12px;
    font-size: 1em;
  }
  .arrow-down {
    margin-bottom: 3%;
  }
  .hero-box {
    display: block;
    width: 93%;
    margin-right: auto;
    margin-bottom: 3%;
    margin-left: auto;
    padding: 4% 5% 3%;
  }
  .box-text {
    margin-bottom: 15px;
    font-size: 0.95em;
  }
  .button.small-btn {
    /* display: block;
    margin-right: auto;
    margin-left: auto; */
  }

  .add-item-inverse-container .button.small-btn {
    /* display: block;
    margin-right: auto;
    margin-left: auto; */
  }

  .button.price-btn {
    font-size: 1em;
  }
  .button.accont-btn--revisor {
    width: 96%;
  }
  .button.accont-btn--redator {
    width: 96%;
  }
  .section {
    background-color: #fff;
  }
  .redator-boxtitle {
    font-size: 160%;
    line-height: 1.2em;
  }
  .revisor-boxtitle {
    margin-bottom: 10px;
  }
  .header {
    width: 90%;
  }
  .section-header {
    font-size: 1.5em;
  }
  .hero-btn {
    display: block;
    width: 240px;
    margin-right: auto;
    margin-left: auto;
    font-size: 1.1em;
  }
  .giant-text {
    font-size: 2.4em;
  }
  .card {
    display: block;
    width: 95%;
    max-width: auto;
    min-width: auto;
    margin: 5% auto;
  }
  .card.mobile-hidden {
    display: none;
  }
  .section-dark.img-bg {
    padding-bottom: 20%;
    background-position: 0px 100%;
    background-size: 110px;
  }
  .section-dark.light-padding {
    padding-right: 0%;
    padding-left: 0%;
  }
  .progress-item {
    display: block;
    width: 85%;
    margin-right: auto;
    margin-left: auto;
    padding: 15px 10px;
  }
  .container.all-center {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .halfbox {
    margin-top: 3%;
  }
  .halfbox.right-space {
    width: 90%;
  }
  .halfbox.revisor-img {
    height: 200px;
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1;
  }
  .halfbox.interprise-img {
    height: 200px;
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1;
  }
  .halfbox.overlay-turquoise {
    height: 200px;
    padding-top: 5%;
    padding-bottom: 5%;
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1;
  }
  .halfbox.accont-box {
    padding: 5% 3%;
  }
  .halfbox.overlay-revisor {
    height: 200px;
    padding-top: 5%;
    padding-bottom: 5%;
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1;
  }
  .halfbox.overlay-redator {
    height: 200px;
    padding-top: 5%;
    padding-bottom: 5%;
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1;
  }
  .halfbox.overlay-login {
    height: 200px;
    padding-top: 5%;
    padding-bottom: 5%;
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1;
  }
  .halfbox.login-box {
    padding: 5% 3%;
  }
  .pre-title.bold {
    line-height: 1.2em;
  }
  .testemonial-photo.avaliation-photo {
    width: 50px;
  }
  .subtitle {
    font-size: 0.9em;
  }
  .large-container {
    text-align: center;
  }
  .large-container.price-content {
    max-width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .footer-link.last-link {
    margin-bottom: 2%;
    padding-bottom: 3%;
    border-bottom: 1px solid #f0f0f0;
  }
  .logo-footer {
    width: 60px;
    margin-bottom: 5%;
  }
  .paragraph-2 {
    font-size: 1em;
    line-height: 1.4em;
  }
  .menu-mobile {
    background-color: transparent;
    font-size: 2em;
  }
  .menu-mobile.w--open {
    background-color: transparent;
  }
  .navlink {
    padding-left: 15px;
    font-size: 1.2em;
  }
  .nav-container {
    width: 100%;
  }
  .contexto-img {
    width: 100px;
    margin-bottom: 10px;
  }
  .block-mainheader {
    font-size: 1.5em;
  }
  .white-halfbox {
    margin-top: 3%;
  }
  .white-halfbox.right-space {
    width: 90%;
  }
  .one-fourth {
    display: block;
    width: 96%;
    margin-top: 15px;
    margin-right: auto;
    margin-left: auto;
  }
  .table-desc {
    padding-top: 5%;
    text-align: left;
  }
  .price-box {
    display: block;
    max-width: 95%;
    margin: 20px auto;
    padding-top: 4%;
    padding-bottom: 6%;
  }
  .stripe-turquoise {
    margin-top: 12px;
    margin-bottom: 12px;
  }
  .price-item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-right: 20px;
    padding-left: 20px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-right-style: none;
  }
  .price-item.top {
    display: block;
    height: 45px;
    font-size: 16px;
  }
  .little-p.darkblue.mobile-only {
    display: block;
  }
  .little-p.mobile-only {
    display: block;
  }
  .price-img {
    height: 22px;
  }
  .desktop-only {
    display: none;
  }
  .overlay-text {
    width: 80%;
    font-size: 1.1em;
  }
  .turquoise-list {
    padding-right: 0px;
    padding-left: 0px;
    font-size: 1em;
  }
  .step-revisor {
    width: 18px;
    height: 18px;
    margin-right: 3px;
    margin-left: 0px;
    padding-top: 4px;
    font-size: 0.6em;
    line-height: 1.4em;
  }
  .text-block-2 {
    font-size: 0.8em;
  }
  .form {
    width: auto;
    padding-right: 5px;
    padding-left: 5px;
  }
  .row {
    width: auto;
  }
  .accont-input {
    width: 96%;
  }
  .separator {
    width: auto;
  }
  .account-linkbox {
    width: auto;
  }
  .gender {
    width: auto;
  }
  .demonstratrion {
    display: block;
    width: 95%;
    max-width: auto;
    min-width: auto;
    margin: 5% auto;
  }
  .demonstratrion.mobile-hidden {
    display: none;
  }
  .avatar-tooltip {
    left: -15px;
    bottom: 15px;
    width: 100px;
    font-size: 0.8em;
  }
  .capa-tooltip {
    left: 200px;
    top: 8px;
    width: 100px;
    font-size: 0.8em;
  }
  .account-link {
    color: #55ddd2;
  }
  .account-link:hover {
    color: #fff;
  }
  .home-menu {
    background-color: transparent;
    color: #fff;
    font-size: 2em;
  }
  .home-menu.w--open {
    background-color: transparent;
    color: #fff;
  }
  .blue-list {
    padding-right: 0px;
    padding-left: 0px;
    font-size: 1em;
  }
  .step-redator {
    width: 18px;
    height: 18px;
    margin-right: 3px;
    margin-left: 0px;
    padding-top: 4px;
    font-size: 0.6em;
    line-height: 1.4em;
  }
  .gray-list {
    padding-right: 0px;
    padding-left: 0px;
    font-size: 1.2em;
  }
  .account-box {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .account-halfleft {
    width: 100%;
  }
  .account-halfright {
    width: 100%;
  }
  .testemonial-name.bold {
    line-height: 1.2em;
  }
  .two-thirds {
    display: block;
    width: 96%;
    margin-right: auto;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: 15px;
    padding-right: 0%;
  }
  .one-third {
    display: block;
    width: 96%;
    margin-right: auto;
    margin-left: auto;
    padding-left: 0%;
  }
  .perfil-box {
    padding-top: 6%;
    padding-bottom: 6%;
    font-size: 12px;
    text-align: left;
  }
  .price-text.bold {
    line-height: 1.2em;
  }
  .perfil-especification {
    font-size: 1.2em;
  }
  .perfil-item.aligner-item {
    margin-bottom: 10px;
    font-size: 12px;
    text-align: left;
  }
  .filter-top {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-right: auto;
    margin-left: auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .filter-btn {
    width: 40px;
  }
  .filter-input {
    text-align: center;
  }
  .open-filter {
    width: 70px;
  }
  .filter-dropdown {
    position: fixed;
    z-index: 9990;
    overflow: scroll;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 9999;
    /* display: block; */
    width: 100%;
    margin-bottom: 0px;
    padding-top: 48px;
    padding-right: 0px;
    padding-left: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    background-color: #34aeff;
    /* opacity: 1; */
  }
  .filter-item {
    display: block;
    width: 70%;
    margin-right: auto;
    margin-bottom: 2%;
    margin-left: auto;
    padding-top: 5%;
    padding-bottom: 5%;
    border-bottom: 1px solid hsla(0, 0%, 94%, 0.65);
    border-right-style: none;
    font-size: 16px;
  }
  .filter-sep {
    display: none;
  }
  .filter-item.last-item {
    padding-right: 25px;
    border-bottom-style: none;
  }
  .support-text {
    display: none;
  }
  .star-medium {
    width: 40px;
    height: 30px;
  }
  .info-boxes {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
  }
  .info-boxes.price-content {
    max-width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .badge {
    display: block;
    width: 120px;
    height: 120px;
    margin-top: 5%;
    margin-right: auto;
    margin-left: auto;
    padding: 10px;
  }
  .publicity-text {
    font-size: 2.1em;
  }
  .publicity-descont {
    font-size: 2.8em;
  }
  .perfil-info {
    padding-left: 0%;
  }
  .giant-text-turquoise {
    font-size: 2.4em;
  }
  .quant {
    font-size: 1.2em;
  }
  .close-filter {
    display: block;
    opacity: 1;
    position: absolute;
    z-index: 9993;
    top: 0px;
    right: 0px;
    display: block;
    width: 50px;
    margin-top: 10px;
    margin-right: 10px;
    padding: 10px;
    font-family: 'Fontawesome webfont', sans-serif;
    color: #fff;
    font-size: 30px;
    text-align: center;
  }
  .close-filter:hover {
    color: #95989a;
  }
  .link {
    color: #fff;
    text-decoration: none;
  }
  .link:hover {
    color: #3b5998;
  }
}

@font-face {
  font-family: 'Helveticaneuelt roman';
  src: url('../fonts/HelveticaNeueLT-Roman.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Fontawesome webfont';
  src: url('../fonts/fontawesome-webfont.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Fontawesome';
  src: url('../fonts/FontAwesome.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

/* Example Styles for React Tags*/
div.ReactTags__tags {
  position: relative;
}

/* Styles for the input */
div.ReactTags__tagInput {
  width: 200px;
  border-radius: 2px;
  display: inline-block;
}
div.ReactTags__tagInput input.ReactTags__tagInputField,
div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  height: 31px;
  margin: 0;
  font-size: 12px;
  width: 100%;
  border: 1px solid #eee;
}

/* Styles for selected tags */
div.ReactTags__selected span.ReactTags__tag {
  border: 1px solid #ddd;
  background: white;
  font-size: 12px;
  display: inline-block;
  padding: 5px;
  margin: 0 5px;
  cursor: move;
  border-radius: 2px;
}
div.ReactTags__selected a.ReactTags__remove {
  color: #aaa;
  margin-left: 5px;
  cursor: pointer;
}

/* Styles for suggestions */
div.ReactTags__suggestions {
  position: absolute;
}
div.ReactTags__suggestions ul {
  list-style-type: none;
  box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
  background: white;
  width: 200px;
}
div.ReactTags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 5px 10px;
  margin: 0;
}
div.ReactTags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}
div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
  background: #b7cfe0;
  cursor: pointer;
}

span.ReactTags__tag {
  color: #55ddd2;
}
