.about .accont-input {
  margin-top: 10px;
  width: 100%;
}

.box-flex {
  display: flex;
  flex-direction: column;
}
.box-flex .box-text {
  flex: 1;
}

.box-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 5%;
  align-items: center;
}
.box-center {
  align-items: center;
}
