.flex-container-receiver {
  max-width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.flex-item-receiver {
  width: 297px;
  height: 244px;
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: flex-start;
}

.flex-item-receiver p {
  width: 100%;
}

.flex-item-receiver div {
  width: 100%;
  text-align: center;
  margin-bottom: 0px;
  word-break: break-word;
  padding-bottom: 10px;
}

@media only screen and (max-width: 375px) {
  .flex-container-receiever {
    flex-direction: column;
    justify-content: space-evenly;
  }

  .flex-item-receiver {
    width: 100%;
    margin-bottom: 20px;
    height: fit-content;
    align-items: center;
  }
  .flex-item-receiver div {
    width: 90%;
    text-align: center;
    margin-bottom: 0px;
    word-break: break-word;
    padding-bottom: 10px;
  }
  .flex-item-receiver p {
    width: 80%;
  }
}
