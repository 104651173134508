#container {
  display: none;
}

@media only screen and (max-width: 1200px) {
  /* Classes relativas ao conteiner: uma padrão, e duas controladas via evento, para o efeito de overlay */
  #container {
    display: flex;
    justify-content: flex-start;
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: 100%;
    z-index: 999;
  }

  .showed {
    background-color: rgba(0, 0, 0, 0.5);
  }
  .hidden {
    background-color: transparent;
  }

  /* Configurações padrão do componente pai, com todos os links. Inclui flexbox e posicionamento */
  .column-basics {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    position: absolute;
    right: -40vw;
    margin-top: 100px;
    width: 40%;
    height: max-content;
    background-color: white;
    border-radius: 10px;
  }

  /* Classes de controle da sidebar */
  .opened-sidebar {
    transition: 0.5s;
    transform: translateX(-104%);
  }

  .closed-sidebar {
    transition: 0.5s;
  }

  /* Id utlizado somente para o último link, a fim de sobrescrever o css padrão processado no SCSS */
  #last {
    border: none;
  }
}
