.accont-box .error {
  width: 300px;
}

.accont-box .payment-step .accont-input {
  width: 100%;
}

.accont-box .payment-step .error {
  width: auto;
}

.accont-box .payment-step .payment-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.accont-box .payment-step .payment-card > *:not(*:last-child) {
  margin-right: 1em;
}

.accont-box .price-box {
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 0;
  margin-right: 10px;
  cursor: pointer;
}
.accont-box .price-box.selected {
  background: #55ddd2;
}
.accont-box .price-box.selected .stripe-turquoise {
  background: white;
}
.accont-box .price-box.selected * {
  color: white;
}
.paragraph-3 {
  font-size: 1em;
  line-height: 1.4em;
  color: #696969;
}
