.recover-container {
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  flex-direction: column; }
  .recover-container .marca {
    width: 160px;
    height: 110px;
    margin: 50px auto 0; }
  .recover-container .turquoise-list {
    padding: 0;
    margin: 0 10px 0 0;
    color: #9A9D9F; }
  .recover-container .title-container {
    display: flex; }
    .recover-container .title-container .title {
      color: #9A9D9F; }
  .recover-container .title-padding {
    padding: 20px 30px 0; }
  .recover-container .box {
    width: 350px;
    min-height: 200px;
    background: #fff;
    box-shadow: 0 1px 5px 1px rgba(32, 32, 32, 0.2);
    border-radius: 5px;
    margin: 30px auto 0; }
  .recover-container .w-input, .recover-container .button {
    width: 100%; }
  .recover-container .divider {
    height: 2px;
    width: 100%;
    background: #f0f0f0;
    margin: 10px 0 0; }
  .recover-container .form {
    padding: 20px 30px; }
  .recover-container .button-cancel {
    color: #34aeff;
    border: none;
    background: transparent; }
