.react-date-picker, .react-date-picker__button {
  width: 100%; }

.react-date-picker__button {
  border: none !important; }

.w-form-done, .w-form-fail {
  margin-top: 20px;
  display: block;
  color: black; }

.radio-input {
  display: flex;
  align-items: center;
  margin-bottom: 15px; }
  .radio-input .w-input {
    margin: auto; }
