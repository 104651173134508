.modal-container {
  position: fixed;
  z-index: 1000000000;
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3); }
  .modal-container .box {
    display: flex;
    flex-direction: column;
    width: 430px;
    padding: 30px 40px;
    background-color: white;
    border-radius: 5px;
    align-self: center;
    max-height: 90vh;
    overflow-y: auto; }
  .modal-container .modal-title {
    text-align: center;
    margin-bottom: 0; }
  .modal-container .modal-text {
    font-size: 13px;
    text-align: center;
    color: #95989a;
    line-height: 1.6em;
    font-weight: 300; }
  .modal-container .modal-icon {
    align-self: center; }
  .modal-container .button {
    width: 100%; }
  .modal-container .w-form .w-input {
    margin-top: 1.3em; }
  .modal-container .modal-counter-proposal-buttons {
    display: flex;
    justify-content: center; }
    .modal-container .modal-counter-proposal-buttons .button {
      width: auto; }
