.admin-clients-container {
  display: grid;
  grid-template-columns: auto auto auto auto;
  justify-content: center;
  grid-column-gap: 50px;
  grid-row-gap: 30px;
  margin-bottom: 5vh;
}

.admin-clients-container .top-row {
  margin-top: 16px;
  padding-left: 5%;
  padding-right: 5%;
  height: 80px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

 .admin-clients-container .card-container{
  height: 400px;
  width: 20vw;
  box-shadow: 0 2px 4px 1px hsl(204deg 2% 59% / 50%);
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0,0,0,.125);
  border-radius: .25rem;
}

.pre-title {
  margin-top: 5vh;
  margin-bottom: 5vh;
}

.top-card {
  height: 4px;
  width: 100%;
  background-color: #3898ec;
}

.admin-clients-container .email {
  color: #3898ec;
  font-size: 1em;
  overflow-wrap: break-word;
  word-wrap: break-word;
  width: 232px;
  padding-left: 5%;
}

.admin-clients-container .tag  {
  color: #afafaf;
  font-size: 1em;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  width: 232px;
  margin-top: 5%;
  padding-left: 5%;
}

.admin-clients-container .date_created {
  color: #3898ec;
  font-size: 1em;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  width: 232px;
  margin-bottom: 5%;
  padding-left: 5%;
}

.admin-clients-container .whatsapp {
  padding-left: 5%;
}

.name {
  font-size: 1.25em;
  height: fit-content;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #3898ec;
}

.three-fifties {
  margin: 0 0;
  height: 100%;
  width: calc(60% - 8px);
}

.admin-clients-container .two-fifties {
  padding-left: 0;
  margin: 0 0;
  height: 100%;
  width: calc(40% - 8px);
}
