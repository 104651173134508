.foto-container .icone {
  color: gold;
  width: 20%;
  height: 20%;
}
.foto-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  background-repeat: no-repeat;
}
.foto-container .span-parceiro {
  display: none;
  border-radius: 2px;
  font-size: 1em;
  text-align: center;
  align-items: flex-end;
  margin: 8vh 0 0 7.5vw;
  max-width: 9.5vw;
}
@media screen and (max-width: 75em) {
  .foto-container .span-parceiro {
    font-size: 1em;
    margin: 11vh 0 0 11vw;
    max-width: 12vw;
  }
}
@media screen and (max-width: 55em) {
  .foto-container .span-parceiro {
    font-size: 0.9em;
    margin: 10vh 0 0 17vw;
    max-width: 20vw;
  }
}
.foto-container:hover .span-parceiro {
  z-index: 100;
  position: absolute;
  display: block;
  background: #111;
  filter: alpha(opacity=80);
  opacity: 0.8;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=80)'; /*--IE 8 Transparency--*/
  color: #fff;
  border-top: 1px solid #999;
}
