.accont-box .error {
  width: 300px; }

.accont-box .payment-step .accont-input {
  width: 100%; }

.accont-box .payment-step .error {
  width: auto; }

.accont-box .payment-step .payment-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between; }
  .accont-box .payment-step .payment-card > *:not(*:last-child) {
    margin-right: 1em; }

.accont-box .price-box {
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 0;
  margin-right: 10px;
  cursor: pointer; }
  .accont-box .price-box.selected {
    background: #55ddd2; }
    .accont-box .price-box.selected .stripe-turquoise {
      background: white; }
    .accont-box .price-box.selected * {
      color: white; }

.file-btn {
  width: 40%;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 32%;
  margin-bottom: 1%;
  font-size: 1em;
  font-weight: bold;
  text-align: center;
  border-radius: 5px;
  background-color: #55ddd2;
  color: white; }

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  width: 15%; }
