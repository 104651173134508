.perfil-sidebar {
  padding-bottom: 2em; }
  .perfil-sidebar .price {
    padding-top: 2em; }
    .perfil-sidebar .price * {
      text-align: center; }
    .perfil-sidebar .price h4 {
      color: #34aeff; }
    .perfil-sidebar .price p {
      font-weight: 500;
      font-size: 3em;
      color: #95989A; }
  .perfil-sidebar .divider-gray {
    height: 1px;
    margin: 0 auto;
    width: 96%;
    background: #D0D0D0; }
  .perfil-sidebar .error {
    color: tomato; }
  .perfil-sidebar .perfil-titles {
    text-align: center;
    color: #55ddd2;
    font-size: 1.8em;
    line-height: 1.5em;
    font-weight: 500;
    margin-bottom: 2em; }
  .perfil-sidebar .msg-subtitle-revisor {
    margin: 0 auto;
    color: red;
    width: 90%;
    line-height: 1.3em;
    font-style: italic;
    font-size: 1.1em;
    font-weight: 400; }
  .perfil-sidebar .perfil-list {
    width: 80%;
    margin: 2em auto 0; }
    .perfil-sidebar .perfil-list .list-style {
      margin-top: 5px;
      margin-right: 5px;
      padding: 5px; }
  .perfil-sidebar .proposta-descricao {
    margin: 2em 0; }
    .perfil-sidebar .proposta-descricao > * {
      margin-top: 1em; }
  .perfil-sidebar .proposta-subtitle {
    margin: 0 auto;
    color: #34aeff;
    width: 90%;
    line-height: 1.3em;
    font-style: italic;
    font-size: 1.1em;
    font-weight: 400; }
  .perfil-sidebar .button {
    margin: 3em auto 0; }
  .perfil-sidebar .slider-label {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 4em; }
    .perfil-sidebar .slider-label .label-left {
      color: #9599A4;
      font-size: 1.1em; }
    .perfil-sidebar .slider-label .label-right {
      color: #95989A;
      font-weight: 600;
      font-size: 1.1em; }
